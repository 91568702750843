import React, { useContext, useEffect, useState } from "react";
import CookiesJS from "js-cookie";
import { ReactComponent as IconLogo } from "../../../assets/img/img-bsrcomputotal.svg";
import Warn from "../containerWarning/containerWarn";
import LenguajeContext from "../../../context/lenguaje/lenguajeContext";
import { consulta } from "../../../utils/Requests/requests.Controller";

//**Components**//

//**Methods**//

/**
 *@author Sebastian Ocampo Roa(15-09-2021)
 *@version 1.0
 *@lastCommit
 */

const ContainerBaseLogin = (props) => {
  /************************
   * @arguments
   ************************/
  const [controlsPage, setControlsPage] = useState({});
  const { stateLanguage } = useContext(LenguajeContext);
  const [textOnPages, setTextOnPages] = useState({});

  const indexedDB = window.indexedDB;
  let db;

  const requestInfo = indexedDB.open("textList", 1);
  useEffect(() => {
    requestInfo.onsuccess = async () => {
      // alert('sdsdsd')
      db = requestInfo.result;
      setTextOnPages(await consulta(db));
    };
  }, []);

  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje != 0) {
      stateLanguage.lenguaje.map((users) => {
        if (users.path == "controls" || users.path == "home") {
          users.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
          });
        }
      });
      setControlsPage(controlsPage);
    } else {
      if (Object.entries(textOnPages).length > 0) {
        textOnPages.ssoPagesData.map((users) => {
          if (users.path == "controls" || users.path == "home") {
            users.webcontrolspages.map((data) => {
              controlsPage[data.idHtml] = data.languagewebcontrols[0];
            });
          }
        });
        setControlsPage(controlsPage);
      }
    }
    // })
  }, [textOnPages, stateLanguage]);
  /************************
   * @methods
   ************************/
  let estilo = {
    width: "57%",
    height: "77%",
    paddingTop: "10%",
  };
  const _handleOpenPanel = () => {
    props.openPanel();
  };
  const invalidGetLng = (e) => {
    if (typeof props.valid === "function") {
    props.valid(e);
    }
  };
  /************************
   * @hooks
   ************************/

  /************************
   * @resturns
   ************************/
  return (
    <>
    {props.verificationLink?
    <Warn
    openPanel={_handleOpenPanel}
    invalidGetLng={(e) => invalidGetLng(e)}
  />
    :
    <>
        <div className="main__container">
          <div className="container--login degradado estile-computer">

            <img
              className="main__container-img"
              src={props.imageBackground}
              alt=""
            />
          </div>
          {/* <div className="container--curve estile-computer">
                    <IconLogo className='estile-computer' style={estilo} />
                </div> */}
          <div className="login--fond-degrade">
            <div className="login--fond-base">
          <div className="login--container-header">{props.header}</div>

              <div className="sub-zero w10">
                <div className="login-container-center w10">
                  <div className="mrg-1">
                  <img
                    src={props.logoPath}
                    alt="LOGO"
                    className="pos-sta logo-client"
                  />
                  {props.hideTextBsr?props.hideTextBsr:<p className="txt-c">{controlsPage.lblPasswordChangeManager?.value}</p>}
                  </div>
                  <div className="login--container-main w10">{props.main}</div>
                </div>
              </div>
              <div id="abajo" className="login--container-footer">
                <div className="c--flex-wsb d--flex w10 button-tyc">
                  <a
                    href="#"
                    onClick={() => {
                      props.footer();
                      props.titleTyC(controlsPage.urlTermsCondition?.value);
                    }}
                    className="hipertext-base hipertext--second"
                  >
                    {controlsPage.urlTermsCondition?.value}
                  </a>

                  <a
                    href="#"
                    onClick={() => {
                      props.footer();
                      props.titleTyC(controlsPage.urlTermsCondition?.value);
                    }}
                    className="hipertext-base hipertext--second"
                  >
                    ...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    }
    </>
  )
  };

export default ContainerBaseLogin;
