import axios from 'axios'
import JSCookies from 'js-cookie'

import ContrlModel from './models.Controller' 
import {SSO_URI, SERVISE_URI, PATH_SSO} from '../../assets/settings/settinsrouters'
import { render } from 'react-dom'
import ModalFluent from '../../components/tools/modalFluent/ModalFluent'


const url = `${SERVISE_URI}api`
const token = JSCookies.get('token')

const contentType = {
    'encode': 'application/x-www-form-urlencoded',
    'json': 'application/json;charset=UTF-8',
    'form': 'multipart/form-data'
}

const GetRequest = async({ category, method, param, body, id, tenant, headerEx }) => {
    const data = await axios(ContrlModel({
        id: id,
        method: method,
        params: param,
        body: body,
        category: category,
        token: token,
        tenant: tenant,
        headerEx
    })).then((res) => {
        return res.data
    }).catch(error => {
        if (error.response) {
            return error.response.data
        }
    })

    return data
}


export const FetchGet = ({ category, method, param, body, id, tenant, headerEx }) => {
    return Promise.all([
        GetRequest({ category: category, method: method, param: param, body: body, id: id, tenant: tenant, headerEx })
    ]).then(([data]) => {
        return { data };
    });
}

export const actualiza =(db,data) =>{
    const transaction = db?.transaction(['text'],'readwrite')
    const objectStore = transaction?.objectStore('text')
    
    const request = objectStore?.put(data)

    // request.onsuccess = () =>{
    //     console.log('funciona act')
    // }
}

export const consulta = (db) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['text'], 'readonly')
        const objectStore = transaction.objectStore('text')
        const conexion = objectStore.openCursor()

        conexion.onsuccess = (e) => {
            const cursor = e.target.result
            if (cursor) {
                resolve(cursor.value)
            }
        }
    })

}

const actionOnClick =(data) => {
    if (data) {
        if (data.a == 'a') {
            window.location.href= `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${data.clientName}/LoginForm`
        }
        if (data.a == 'b') {
            window.close()
        }
        
    }
}
export class RequestFetch {
    constructor(page) {
        this.page = page
    }

    getItem({ param, id, tenant, headerEx }) {
        return (FetchGet({
            category: this.page,
            method: 'get',
            param: param,
            id: id,
            tenant: tenant,
            headerEx
        }))
    }

    async getById({ id, tenant, headerEx, reload, cancelIcon }) {
        return await axios.get(`${url}/${this.page}/${id}`, {
            headers: {
                "tenant": tenant,
                ...headerEx
            }
        }).catch(error=>{
            if (error.response) {
                let description = ''
                let title = ''
                if (Array.isArray(error.response.data.errors)) {
                    title=error.response.data.errors[0].title
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description =error.response.data.errors[i].code + ' - ' + error.response.data.errors[i].detail;
                    }
                }
                } else if (typeof error.response.data.errors === 'object') {
                    title=error.response.data.title

                  for (const property in error.response.data.errors) {
                      for (let i = 0; i < error.response.data.errors[property].length; i++) {
                        description = `${description}\n${error.response.data.errors[property][i]}`;
                        
                      }
                  }              
                }
                let info ={
                  title:title,
                  description:description,
                  type:'danger',
                }
                render(<ModalFluent props={info} 
                  actionOnClick={(e)=>actionOnClick(e)}
                  reload={reload? reload:false}
                  cancelIcon={cancelIcon}
                  />,
                  document.getElementById("modal")
                );
            }
        })
    }

    async getByIdWithParams({ id, tenant, params = {}, headerEx }) {
        const queryUrl = new URL(`${url}/${this.page}/${id}`);
        Object.entries(params).forEach(([key, value]) => queryUrl.searchParams.append(key, value));
        return await axios.get(queryUrl.toString(), {
            headers: {
                "tenant": tenant,
                ...headerEx
            }
        })
    }

    async createItem({ body, ct, tenant, headerEx, option, reload, validator, cancelIcon, infoModal }) {
        await axios.post(`${url}/${this.page}`, body, {
            headers: {
                'content-type': contentType[ct],
                'tenant': tenant,
                // 'Authorization': `Bearer ${token}`,
                ...headerEx
            }
        }).then(e=>{
            let info ={
                title:infoModal.title,
                description:infoModal.description,
                type:'success',
            }
              render(<ModalFluent props={info} 
                actionOnClick={(e)=>actionOnClick(e)}
                objectRequest={option}
                reload={reload? reload:false}
                cancelIcon={cancelIcon}
                redirect={validator?validator:false}        
                />,
                document.getElementById("modal")
              );
        })
        .catch(error=>{
            if (error.response) {
                let description = ''
                let title = ''
                if (Array.isArray(error.response.data.errors)) {
                    title=error.response.data.errors[0].title
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description =error.response.data.errors[i].code + ' - ' + error.response.data.errors[i].detail;
                    }
                }
                } else if (typeof error.response.data.errors === 'object') {
                    title=error.response.data.title

                  for (const property in error.response.data.errors) {
                      for (let i = 0; i < error.response.data.errors[property].length; i++) {
                        description = `${description}\n${error.response.data.errors[property][i]}`;
                        
                      }
                  }              
                }
                let info ={
                  title:title,
                  description:description,
                  type:'danger',
                }
                render(<ModalFluent props={info} 
                  actionOnClick={(e)=>actionOnClick(e)}
                  objectRequest={option}
                  reload={reload? reload:false}
                  cancelIcon={cancelIcon}
                  redirect={validator?validator:false}        
                  />,
                  document.getElementById("modal")
                );
            }
        })
    }

    async createItemParams({body, ct, param, tenant, option, reload, headerEx, disableClose, validator, cancelIcon, txtButton}){
        console.log("create txtButton", txtButton);
        return await axios.post(`${url}/${this.page}?${param}`,body,{
            headers: {
                'content-type': contentType[ct],
                'tenant': tenant,
                ...headerEx
            }
        })/* .then(e=>{
            alert('si')
            let infopax ={
                title:'Actualización',
                description:'Se ha registrado la información de forma correcta',
                type:'success',
              }
              render(<ModalFluent props={infopax} 
                actionOnClick={actionOnClick}                
                />,
                document.getElementById("modal")
              );
        }) */
        .catch(error=>{
            if (error.response) {
                let description = ''
                let title = ''
                if (Array.isArray(error.response.data.errors)) {
                    title=error.response.data.errors[0].title
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description =error.response.data.errors[i].code + ' - ' + error.response.data.errors[i].detail;
                    }
                }
                } else if (typeof error.response.data.errors === 'object') {
                    title=error.response.data.title

                  for (const property in error.response.data.errors) {
                      for (let i = 0; i < error.response.data.errors[property].length; i++) {
                        description = `${description}\n${error.response.data.errors[property][i]}`;
                        
                      }
                  }              
                }
                let info ={
                  title:title,
                  description:description,
                  txtButton:txtButton,
                  type:'danger',
                }
                render(<ModalFluent props={info} 
                  actionOnClick={(e)=>actionOnClick(e)}
                  objectRequest={option}
                  reload={reload? reload:false}
                  cancelIcon={cancelIcon}
                  disableClose={disableClose}
                  redirect={validator?validator:false}        
                  />,
                  document.getElementById("modal")
                );
            }
        }) 
    }

    async createItemParamsRecover({body, ct, param, tenant, option, reload, headerEx, validator, cancelIcon}){
        return await axios.post(`${url}/${this.page}?${param}`,body,{
            headers: {
                'content-type': contentType[ct],
                'tenant': tenant,
                ...headerEx
            }
        })
    }

    async createItemParamsPass({body, ct, tenant, verification, headerEx, reload, cancelIcon}){
        return await axios.post(`${url}/${this.page}`, body, {
            headers: {
                'content-type': contentType[ct],
                'tenant': tenant,
                ...headerEx
            }
        }).catch(error => {
            if (error.response) {
                let description = ''
                let title = ''
                let code = ''
                if (Array.isArray(error.response.data.errors)) {
                    title=error.response.data.errors[0].title
                for (const property in error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                      description = error.response.data.errors[i].code + ' - ' + error.response.data.errors[i].detail;
                      code = error.response.data.errors[i].code;
                      if (code == 'FG-0163' || code == 'FG-1012') {
                        reload=true
                      }
                    }
                }
                } else if (typeof error.response.data.errors === 'object') {
                    title=error.response.data.title
                  for (const property in error.response.data.errors) {
                      for (let i = 0; i < error.response.data.errors[property].length; i++) {
                        description = `${description}\n${error.response.data.errors[property][i]}`;
                      }
                  }
                }
                let info ={
                  title:title,
                  description:description,
                  type:'danger',
                }
                render(<ModalFluent props={info} 
                  actionOnClick={(e)=>actionOnClick(e)}
                  reload={reload?reload:false}
                  cancelIcon={cancelIcon}       
                  />,
                  document.getElementById("modal")
                );
            }
        })
    }

    async updateItem({ body, id, ct }) {
        await axios.put(`${url}/${this.page}/${id}`, body, {
            headers: {
                'content-type': contentType[ct],
                'Authorization': `Bearer ${token}`,
            }
        }).then(e => {
            console.log(e)
        }).catch(error => {
            console.log(error)
        })
    }
    async deleteItem(id) {
        await axios.delete(`${url}/${this.page}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(e => {
            console.log(e)
        }).catch(error => {
            console.log(error)
        })
    }
}
