import DomainModels from './domain.Model'

import {SERVISE_URI} from '../../assets/settings/settinsrouters'

const appKey = 'BSR SSO'
const url = `${SERVISE_URI}api`

const ContrlModel =({ method, params, body, category, token, id, tenant, headerEx })=>{
    const headers = {
        'Basic':{
            'Accept': 'application/json',
            'content-type': 'application/json',
            'tenant': tenant,
            ...headerEx
        },
        'Oauth':{
            'Accept': 'application/json',
            'content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenant': tenant
        }
    }

    const REQUEST_MODEL =  DomainModels({
            id: id,
            url: url,
            method: method,
            body: body,
            params: params,
            appkey: appKey,
            headers: headers['Basic'], 
            category: category
        })

    return REQUEST_MODEL
}

export default ContrlModel