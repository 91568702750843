//****Librarys ****//
import React, { Fragment, useState } from 'react';

/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const ImgQuat= (props) =>{       

    /************************
     * @arguments 
     ************************/

    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/

    /************************
     * @resturns 
     ************************/

        return(
            <div className="container--imgquat">
                <img src={props.urlimg} />
            </div>
        );
}

export default ImgQuat;