//****Librarys ****//
import React, { useEffect, useState, useContext, forwardRef } from "react";
import AuthContext from "../../../context/auth/authContext";
import { DecodeB4 } from "../../../utils/encode/encode";
import { IconButton } from "@fluentui/react/lib/Button";
import CookiesJS from "js-cookie";
import { RequestFetch } from "../../../utils/Requests/requests.Controller";
import { ChoiceGroup } from "@fluentui/react/lib/ChoiceGroup";
import { Image } from "@fluentui/react/lib/Image";

//*****Componnents*****//

/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const ContainerCaptcha = forwardRef((props, ref) => {
  const [renderCatpcha, setRenderCatpcha] = useState(null);
  const [titleImage, setTitleImage] = useState(null);
  const nameClient = CookiesJS.get("nameClient");
  const { state, getError } = useContext(AuthContext);
  /************************
   * @arguments
   ************************/
  const Requests = new RequestFetch("Access/captcha");

  /************************
   * @methods
   ************************/
  //  useEffect(() => {
  //   if (props.idLanguage) {
  //     onClick()
  //   }
  // }, [props.idLanguage])

  /************************
   * @hooks
   ************************/


  /************************
   * @resturns
   ************************/
  useEffect(() => {
    let structImageCaptcha = [
      {
        nameCaptcha: props.dataCaptcha.dataCaptcha.nameImage,
      },
      { arrayImageCaptcha: [] },
    ];
    props.dataCaptcha.dataCaptcha.imagesBase64.forEach((element) => {
      let img64 = `data:image/png;base64,${element}`;
      structImageCaptcha[1].arrayImageCaptcha.push({
        key: element,
        imageSrc: img64,
        selectedImageSrc: img64,
        imageSize: { width: 25, height: 25 },
      });
    });
    setRenderCatpcha(structImageCaptcha);
  }, [props]);

  const actionOnChange = (e, data) => {
    props.actionOnChange(data.key);
  };

  useEffect(() => {
    if(renderCatpcha !== null){
      setTitleImage(renderCatpcha[0].nameCaptcha)
    }
  }, [renderCatpcha])

  const refreshCaptcha = () => {
    let structImageCaptcha = [
      {
        nameCaptcha: props.dataCaptcha.dataCaptcha.nameImage,
      },
      { arrayImageCaptcha: [] },
    ];
    const user = props.isUserEncrypted ? props.userName : state.user;
    const role = props.isUserEncrypted ? props.role : state.roll;
    let params = {
      isEncrypted: props.isUserEncrypted ? props.isUserEncrypted : false,
      userName: user
    };
    Requests.getByIdWithParams({
      id: `${props.idLanguage},${role}`,
      tenant:nameClient,
      params,
      headerEx:{idLanguageUser:CookiesJS.get('idLng')}
    })
    .then((data) => {
      let jsonFormat = JSON.parse(data.request.response);
      props.onUpdateCaptcha && props.onUpdateCaptcha(jsonFormat.captchadata);
      structImageCaptcha[0].nameCaptcha = jsonFormat.captchadata.nameImage;
      jsonFormat.captchadata.imagesBase64.forEach((element) => {
        //let svgImg = DecodeB4({ value: element, value2: element });
        let img64 = `data:image/png;base64,${element}`;
        // let img64 = `${element}`;
        structImageCaptcha[1].arrayImageCaptcha.push({
          key: element,
          imageSrc: img64,
          selectedImageSrc: img64,
          imageSize: { width: 25, height: 25 },
        });
      });
      setRenderCatpcha(structImageCaptcha);
    });
  };

  const imageProps = {
    className: "imgNameImage",
  };
  const emojiIcon = { iconName: "Refresh" };
  return (
    <>
      <div className="container--captcha">
        {renderCatpcha !== null ? (
          <>
            <div className="pd-txt">
              <p style={{textAlign: 'center', margin:'0px'}}>{props.order}</p> 
            </div>
            <div className="iconLabel">
              <div className="aa">
                <Image
                  {...imageProps}
                  src={titleImage}
                  alt=""
                />
              </div>
              <div className="ss">
                <IconButton
                  {...props.ref?{ref:props.ref}:{}}
                  className="button"
                  iconProps={emojiIcon}
                  onClick={refreshCaptcha}
                />
              </div>
            </div>
            <ChoiceGroup
              defaultSelectedKey="bar"
              options={renderCatpcha[1].arrayImageCaptcha}
              onChange={actionOnChange}
              focused={false}
              className="captchaStyle"
              styles={{
                flexContainer: {
                  display: "flex",
                  "flex-flow": "initial",
                  padding: "1px",
                },
              }}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
});

export default ContainerCaptcha;
