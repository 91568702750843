import axios from 'axios'

import OauthModels from './oauth.Models'

export const AuthConnect = async({type, user, roll, leng, credential, accessToken, tenant, acceptedTyC, captchaImage,responseToken})=>{
    const res = await axios(OauthModels({
            type: type, 
            user: user,
            roll: roll,
            leng:leng,
            credential: credential,
            accessToken: accessToken,
            tenant: tenant,
            acceptedTyC: acceptedTyC,
            captchaImage: captchaImage,
            responseToken:responseToken
        })).then(res=>{
            return res
        }).catch(error=>{
            if (error.response) {
                return error.response.data
            }
        })
    return res
}

const ValidateAuth = async({accessToken, refreshToken, cp, type, tenant, leng})=>{
    const res = await axios(OauthModels({
            accessToken: accessToken,
            refreshToken: refreshToken,
            cp: cp,
            type: type,
            leng:leng,
            tenant: tenant
        })).then( response =>{
            return response
        }).catch(error =>{
            if (error.response) {
                return error.response.data
            }
        })
    return res //.data.data.active;
}

const FetchOAuth = ({accessToken, refreshToken, cp, type, tenant, leng})=>{
    return Promise.all([
        ValidateAuth({accessToken, refreshToken, cp, type, tenant, leng})
    ]).then(([data])=>{
        return{data}
    })
}

export default FetchOAuth