import CryptoJS from 'crypto-js' 

export const EncodeB4 = ({value, value2}) =>{
    
    if(value2){
        const encutf = CryptoJS.enc.Utf8.parse(`${value}:${value2}`)
        const encbs4 = CryptoJS.enc.Base64.stringify(encutf)
        return encbs4
    }else{
        const encutf = CryptoJS.enc.Utf8.parse(value)
        const encbs4 = CryptoJS.enc.Base64.stringify(encutf)
        return encbs4
    }
    
}

export const DecodeB4 = ({value, value2}) =>{
    const decbs4 = CryptoJS.enc.Base64.parse(value)
    const decutf = CryptoJS.enc.Utf8.stringify(decbs4)
    return decutf
}