import React from 'react';

const ButtonFloat = (props) =>{
    /************************
     * @arguments 
     ************************/

    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/

    /************************
     * @resturns 
     ************************/
        return(
            <div className={`container--buttonFloat clr--primary ${props.typef}`} 
                onClick={props.type == 1?props.onClick:undefined}
            >
                <a className={props.type == 1?props.icon:undefined} onClick={props.onClick} href={props.href}>{props.special ?props.icon:undefined}</a>
                <p className="buttonFloat--text">{props.text}</p>
            </div>
        );
}

export default ButtonFloat;