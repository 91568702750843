//****Librarys ****//
import React, { useContext, useEffect, useState } from 'react';
import {
    Dropdown
  } from "@fluentui/react/lib/Dropdown";
import { ScreenWidthMaxLarge } from '@fluentui/react';
import { consulta } from '../../../utils/Requests/requests.Controller';
import LenguajeContext from '../../../context/lenguaje/lenguajeContext';
//*****Componnents*****//

//**Methods**//

/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const SelectFile = (props) =>{         
    
    let optionsDrop = []
    props.options.map(option=>{
        optionsDrop.push({id:option.id, key:option.id, text:option.text})
    })
    /************************
     * @arguments 
     ************************/
        const [currentSelect, setCurrentSelect] = useState(0)
        
    /************************
     * @methods 
     ************************/
    
    /************************
     * @hooks 
     ************************/
  
    /************************
     * @resturns 
     ************************/
        return(
            <div className="selectFile--container">
                {props.label?<label>{props.label}</label>:undefined}
                {/* <select defaultValue={0} name={props.name}
                    onChange={(e)=>{setCurrentSelect(e.target.value); props.seletVal(e.target.value); console.log(e.target.value);}}>
                        
                    {props.defaultValue === null? <option key="default" value={0}>{props.defaultValueName}</option>:""}

                    {props.options.map(option=>
                        <option 
                            className={currentSelect == option.id ? 'active': ''}
                            key={option.id} value={option.id}
                        >
                            {option.text}
                        </option>
                    )}
                </select> */}
                <Dropdown
                placeholder={props.placeholder}
                responsiveMode={ScreenWidthMaxLarge}
                options={optionsDrop}
                defaultSelectedKey={props.defaultKey}
                className='style-opt'
                onChange={(e, item)=>{setCurrentSelect(item.key); props.seletVal(item.key);}}
                disabled={props.disabled}
                />
            </div>
        );
}

export default SelectFile;