export default (state, action)=>{
    const {payload, type} = action
    
    const TYPES_GETS = {
        'GET_LENGUAJE':{
            ...state,
            lenguaje: payload
        }
    }

    return TYPES_GETS[type]
}