import {useEffect, useState} from 'react'
import {SERVISE_URI} from '../assets/settings/settinsrouters'

const url = `${SERVISE_URI}api`


export const CHLoginUser = async(requestDomain, idDomain) => {
    const request = requestDomain.page;
    const [updateDatos,setUpdate] = useState(true)
    const [datos,setDatos] = useState()
    const cbDatos=(mydatos)=>{
        setDatos(mydatos.data)
        setUpdate(false)
    }
    const fetchDatos = async () => {
        await fetch(`${url}/${request}/${idDomain}`)
            .then(response=>response.json())
            .catch()
            .then(data=> cbDatos(data) )
    }

    useEffect(()=>{
        if(updateDatos){
            fetchDatos()
        }
    },[updateDatos])
    

    return datos;
}
