import React, {useReducer} from 'react'
import LenguajeReducer from './lenguajeReducer'
import LenguajeContext from './lenguajeContext'

const LenguajeState = (props)=>{
    const initialState = {lenguaje: 0}

    const [stateLanguage, dispatch] = useReducer(LenguajeReducer, initialState) 

    const getLenguaje = (id)=>{
        dispatch({
            type: 'GET_LENGUAJE',
            payload: id
        })
    }

    return(
        <LenguajeContext.Provider value={{stateLanguage, getLenguaje}}>
            {props.children}
        </LenguajeContext.Provider>
    )
}

export default LenguajeState;   
