import React, { useContext, useEffect, useState } from 'react'
import { RequestFetch } from '../utils/Requests/requests.Controller';
import { useNavigate } from 'react-router-dom';
import LenguajeContext from '../context/lenguaje/lenguajeContext';
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
import { render } from 'react-dom';
import CookiesJS from 'js-cookie'
import ResetPassword from './resetPassword';
import ButtonForm from '../components/tools/buttonForm/buttonForm';
import { PATH_SSO, SSO_URI } from '../assets/settings/settinsrouters';

const VerificationChallenge = (props) => {
    const {isForPass, isDisbleUsr,rendOpt} = props.forChallenge
    const requestDomain = new RequestFetch("access/text");
    const userValidation = new RequestFetch("access/supportValidation");
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [controlsPage, setControlsPage] = useState({})
    const [optRender, setOptRender] = useState()
    const [contex, setContex] = useState({})
    const dataQ = window.location.href;
    const dataU = window.location.search;
    const nameClient = CookiesJS.get('nameClient');
    const navigate = useNavigate();
    let url = new URL(document.URL).searchParams;
    
    const resultLng = encodeURI(url.get("idL"));
    const idLng = resultLng.replaceAll("%20", "+")

    const urll = window.location.href;
    const cod = urll.split("cod=")[1]?.split("&")[0];
    const redirectHome = () =>{
        navigate(`/${PATH_SSO ? `${PATH_SSO}/` : ''}${nameClient}/home`)
    }

    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((users) => {
                if (users.path == 'verification-challenge' || users.path == 'enable-account' || users.path == 'controls') {
                    users.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                }
            })
            setControlsPage(controlsPage)
        }
        setOptRender(
            <>
              <br />
              <div className="w10" style={{textAlign:'left'}}>
              <h3 className="mg10-h clr--dark-II">{controlsPage.lblUpdateOffice365MFAstatus?.value}</h3>
              </div>
              <form id="form1" onSubmit={(e)=>habilitarMfa(e,contex, controlsPage)}>
                <label className="mg10-h">
                  {controlsPage.lblToUpdateUserOffice365MFAstatus?.value}
                </label>
                
                <center>
                  <ButtonForm
                    text={controlsPage.smtlUpdateMFA?.value}
                    oneClick={true}
                    className="mg10-h"
                    classNameDiv="mg20-h"
                    formId="form1"
                  />
                  </center>
              </form>
            </>
            )
        // setOptRender(
        //     <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
        //         <p>{controlsPage.lblDuringIdentityValidationNotifiMBeSent?.value}</p>
        //         <button type="submit" onClick={()=>{resetPass()}} className="button--default button--primary ">
        //             {controlsPage.smtContinue?.value}
        //         </button>
        //     </div>
        // )
    }, [stateLanguage])

    const habilitarMfa = async(form, data, controlsPage) => {
        form.preventDefault();
        const paramsResponse = new URLSearchParams({
          username: data.user,
          domain: data.roll
        }).toString();
        let info ={
          title: controlsPage.lblSuccessfulProcess?.value,
          description: controlsPage.lblMFStatusUpdatedSuccessfully?.value,
          type:'success'
        }
        const enableMfa = new RequestFetch(`Access/changeMFAStatus/${data.user}/${data.roll}`);
        const resp = await enableMfa.createItem({
          ct:'json',
          tenant: nameClient,
          headerEx:{
            idLanguageUser:data.idLng,
            supportCode:cod
          },
          cancelIcon:true,
          option:()=>{window.close();redirectHome()},
          infoModal:info,
          validator:true
        });
      }

    useEffect(async() => {
        let controlsPage = {};
        const supportValidation = await userValidation.getItem({
            tenant: nameClient,
            headerEx:{idLanguageUser:idLng, code: cod}
        });
        const supportInfo = supportValidation.data.data
        if (supportValidation.data.data) {
            let contex = {
                roll:supportValidation.data.data.idDomain, 
                user:supportValidation.data.data.userName,
                idLng:supportValidation.data.data.idLanguage
            }
            setContex(contex)
            
            const promiseAccess = await requestDomain.getItem({
                param: {idLanguage: idLng}, 
                tenant: nameClient,
                headerEx:{idLanguageUser:idLng}
            });
            promiseAccess.data.data.map((spd) =>{
                if(spd.path == 'identity-verification' || spd.path == 'verification-challenge' || spd.path == 'enable-account' || spd.path == 'controls') {

                    spd.webcontrolspages.map((data)=>{
                        controlsPage[data.idHtml] = data;
                    })

                }
            })
            setControlsPage(controlsPage)
            if (promiseAccess.data.captchadata && promiseAccess.data.captchadata.languageCode) {
                promiseAccess.data.data.push({langCaptcha:promiseAccess.data.captchadata.languageCode})
            }
            getLenguaje(promiseAccess.data.data)
            
        }else{
            const {title,detail,code} = supportValidation.data.errors[0]
            let infor ={
                title: title,
                description: code + ' - ' + detail,
                type: 'danger'
            }
            renderModal(infor,()=>redirectHome())
        }
        
    }, [])

    const renderModal = (data, action) =>{
        render(<ModalFluent
            cancelIcon={true}
            props={data}
            actionOnClick={()=>{action();}}
            redirect={true}
            objectRequest={()=>{action();}}
            />,
        document.getElementById("modal"))
    }
    
  return (
    <>
    <div id='modal'></div>
        {optRender && (
          optRender
         )}
    </>
  )
}

export default VerificationChallenge