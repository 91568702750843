const DomainModels =({url, params, method, body, headers, id, category})=>{

    const TYPES_DOMAIN ={
        'get': {
            method: 'GET',
            url: `${url}/${category}`,
            headers: headers,
            params: params                      //{ 'idClient', 'domain', 'alias', 'pageSize', 'pageNumber'}
        },
        'getid':{
            method: 'GET',
            url: `${url}/${category}/${id}`,
            headers: headers,
            params:params                       //'DomainDeniedSchedules'
        },
        'post':{
            method: 'POST',
            url: `${url}/${category}`,
            headers: headers,
            body: body
        },
        'put':{
            method: 'PUT',
            url: `${url}/${category}/${id}`,
            headers: headers,
            body: body
        },
        'delete':{
            method: 'DELETE',
            url: `${url}/${category}/${id}`,
            headers: headers,
        }
    }

    return TYPES_DOMAIN[method]
}

export default DomainModels