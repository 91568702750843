//****Librarys ****//
import React, { useEffect, useState, useContext } from 'react';
import { FontIcon, TeachingBubble, TextField } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import LenguajeContext from '../../../context/lenguaje/lenguajeContext';
//*****Componnents*****//
import VirtualKeyBoard from '../keyBoard/keyBoard.component'
import { useMemo } from 'react';
import { consulta } from '../../../utils/Requests/requests.Controller';

//**Methods**//

/**
 *@author Yeison Quiroga(15-04-2021)
 *@version 1.0
 *@lastCommit 
 */

const InputFileFluent = (props) => {

    /************************
     * @arguments 
     ************************/
    const [stateType, setStateType] = useState(true)
    const [stateKey, setStateKey] = useState(false)
    const [currentLayout, setCurrentLayout] = useState('default')
    const [valueInput, setValueInput] = useState('')
    const buttonId = useId('targetButton');
    const [txtValue, setTxtValue] = useState('')
    const [controlsPage, setControlsPage] = useState({});
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);

    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const [count, setCount] = useState(0)
    const [score, setScore] = useState(1)
    const [bot, setBot] = useState(Boolean)

    /************************
     * @methods 
     ************************/

     useEffect(() => {
  
        if (props.listPolicies) {
          if (score ==props.listPolicies.length) {
            setBot(false)
          }else{
            setBot(true)
          }
        }
        
      if(score < 1 || score > (props.listPolicies ? props.listPolicies[score]:"")){
        setScore(1)
        setBot(true)
      }
    }, [score])

    useEffect(async() => {
      let controlsPage = {};
      if (stateLanguage.lenguaje !=0) {
          stateLanguage.lenguaje.map((pass) => {
              if ( pass.path == 'controls')  {
                pass.webcontrolspages.map((data) => {
                  controlsPage[data.idHtml] = data.languagewebcontrols[0];
                })  
              }              
          })          
        setControlsPage(controlsPage)
      }
    }, [stateLanguage])

    const onKeyPress = (button) => {
        // MethodsKeyBoard[button]
        //     ? MethodsKeyBoard[button]()
        //     : 'default'

        if (button !== '{alt}' && button !== '{default}' && button !== '{contrs}' && button !== '{bksp}'
            && button !== '{shift}' && button !== '{enter}' && button !== '{space}' && button !== '{return}') {
            setValueInput(valueInput + button)
            props.valueKeyBoard(valueInput)
        }
    }
    const limitTxt = (newValue)=>{
      if(props.maxLength){
        if (!newValue || newValue.length <= props.maxLength) {
          setTxtValue(newValue || '');
          props.onMessage(false)
        } 
        else{
          props.onMessage(true)
        }
      }else{
        setTxtValue(newValue)
      }
    }

    const MethodsKeyBoard = {
        '{alt}': () => setCurrentLayout('alt'),
        '{default}': () => setCurrentLayout('default'),
        '{shift}': () => setCurrentLayout(currentLayout == 'default' ? 'shift' : 'default'),
        '{contrs}': () => { },
        '{enter}': () => { },
        '{space}': () => setValueInput(valueInput + ' '),
        '{bksp}': () => setValueInput(valueInput.slice(0, -1))
    }
    

    /************************
     * @hooks 
     ************************/
     const SecondaryButtonProps= useMemo( 
        () => ({
          children: 'Anterior',
          onClick: ()=>{setScore(score-1);},
        }),
      );

      const dismissButtonProps = useMemo(
        () => ({
          children: controlsPage.lblUnderstood?.value,
          onClick:()=>{toggleTeachingBubbleVisible();props.setDisable(false);} ,
        }),
        [toggleTeachingBubbleVisible, controlsPage],
      );

      const PrimaryButtonProps = useMemo (
        () => ({
          children: 'Siguiente',
          onClick: ()=>{setScore(score==5);},
        }),
        
      );
    
      useEffect(() => {
        if (props.politics?.length>0 ) {
          toggleTeachingBubbleVisible()
         }
    }, [props.politics])


    /************************
     * @resturns 
     ************************/
    return (
        <div className={`inputFile--container ${props.style}`}>
            {/* {props.label ? <label>{props.label}</label> : undefined} */}
            <div className="est">
            {props.label ? <div className="content"><label>{props.label}</label></div> : undefined}
            {props.politics ? <div className="content"><a id={buttonId} onClick={toggleTeachingBubbleVisible} className='politics'>{props.politics}</a> </div>: undefined}
            </div>
            {props.type != 'password' ?
                <TextField // prettier-ignore
                    suffix={props.suffix}
                    onChange={(e,item) => {props.onChange(item);limitTxt(item)}}
                    className='input-file-fluent'
                    placeholder={props.placeholder}
                    styles={{
                        label: { className: 'my-TextField-label' }
                      }}
                    value={txtValue}
                />
                 : 
                <div className="inputFielt--container-input">
                    <input
                        name={props.name}
                        autoComplete="off"
                        defaultValue={valueInput}
                        placeholder={props.placeholder}
                        type={stateType ? 'password' : 'text'}
                        disabled={props.disable ? true : false}
                        readOnly={props.keyboard ? true : undefined}
                        onClick={() => stateKey ? setStateKey(false) : setStateKey(true) }
                        onChange={(e) => props.onChange(props.keyboard ? valueInput : e.target.value)}
                        maxLength={props.maxLength}
                        minLength={props.minLength}
                    /> 
                    {teachingBubbleVisible && (
                        <TeachingBubble
                        target={`#${buttonId}`}
                        primaryButtonProps={
                          props.listPolicies && props.listPolicies.length>0 
                          ?(bot? dismissButtonProps :dismissButtonProps):dismissButtonProps}
                        // secondaryButtonProps={
                        //   props.listPolicies && props.listPolicies.length>0 && score != 1
                        //   ? dismissButtonProps :''}
                        // footerContent={
                        //   props.listPolicies && props.listPolicies.length>0 
                        //   ?`${score} de ${props.listPolicies ? props.listPolicies.length:""}`
                        //   :''
                        // }
                        //onDismiss={toggleTeachingBubbleVisible}
                        headline={controlsPage.lblPasswordPolicies?.value}
                        className="teching-bubble"
                      >
                        {props.listPolicies && props.listPolicies.length>0 ?
                         <ul className='polits'>
                          {/* <li>{props.listPolicies[(score==props.listPolicies.length ? 0:score )].description}</li>
                          <ol>{props.listPolicies[(score==props.listPolicies.length ? 0:score )].values.map(item=>{
                          if(item.idSecurityPolicyType != 6 ){
                            return(<li>{item.value} </li>)
                          }
                        })}</ol> */}
                        {props.listPolicies.map(items =>{
                          let valuepolicies=""
                          const ultiPos = items.values.length-1
                          const ultiPolitc = items.values[ultiPos]
                        items.values.map(value=>{
                          if(value.idSecurityPolicyType != 6 ){
                        if (ultiPolitc == value) {
                          valuepolicies += value.value 
                          }else{
                          valuepolicies += value.value + ", "
                          }
                          }})
                        return(
                        <div><li>{items.description}</li>
                        <a>{valuepolicies}</a></div>
                         )
                       })}
                         </ul>
                         :<p>El usuario no cuenta con políticas asignadas</p>}
                        
                      </TeachingBubble>
                    )}
                    <span
                        onClick={() => { stateType ? setStateType(false) : setStateType(true) }}                        
                    ><FontIcon aria-label="ChevronLeftSmall"
                       iconName={stateType ? 'RedEye': 'VisuallyImpaired'} 
                    />
                    </span>
                </div>
            }
            {props.keyboard && stateKey ? <VirtualKeyBoard
                layoutName={currentLayout}
                onKeyPress={(button) => onKeyPress(button)}
            /> : undefined}
        </div>
    );
}

export default InputFileFluent;