//****Librarys ****//
import React, { Fragment, useState } from 'react';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';

//*****Componnents*****//
import ButtonFloat from '../components/tools/buttonFloat/buttonFloat.component';
import { useNavigate } from 'react-router-dom';


/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const LoginCode = () =>{       

    /************************
     * @arguments 
     ************************/
        const navigate = useNavigate();
    /************************
     * @methods 
     ************************/
        const Methods = {
            chageSettingAuth:()=>{
                
            },
            redirect:()=>{}
        }
    /************************
     * @hooks 
     ************************/
            
    /************************
     * @resturns 
     ************************/
        return(
            <Fragment>
                <p>Gestor de cambio o recuperación de contraseña</p>
                <label>Opciones de inicio de sesión</label>
                <span>Iniciar sesión con código de verificación de lores impuso, consetetur sadipscing elitr.</span>
                <TextField label="Código enviado" type="number"/>
                <section>
                    <button className="button--default button--primary" onClick={()=>navigate('/')}>ENVIAR</button> 
                </section>
            </Fragment>
        );
}

export default LoginCode;