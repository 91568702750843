//****Librarys ****//
import React, { Fragment, useState } from 'react';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';

//*****Componnents*****//
import ButtonFloat from '../components/tools/buttonFloat/buttonFloat.component';
import ContainerCaptcha from '../components/container/contianerCaptcha/containerCaptcha.component';
// import { usenavigate } from 'react-router';
import { useNavigate } from 'react-router-dom';


/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const ResetUser = () =>{       

    /************************
     * @arguments 
     ************************/
        const navigate = useNavigate();

    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/
                    
    /************************
     * @resturns 
     ************************/
        return(
            <Fragment>
                <p>Gestor de cambio o recuperación de contraseña</p>
                <ButtonFloat icon={<a>&#x1F511;</a>} type={1} typef="btnFloat-header" text="Opciones de inicio de sesión" />
                <label>Ingrese correo alterno</label>
                <TextField/>
                <ContainerCaptcha/>
                <section>
                    <button className="button--default button--primary" onClick={()=>navigate('/')}>SIGUIENTE</button> 
                </section>                    
            </Fragment>
        );
}

export default ResetUser;