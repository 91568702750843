import React from 'react';

const NotAuthorization = () =>{

    /************************
     * @arguments 
     ************************/

    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/
                
    /************************
     * @resturns 
     ************************/
        return(
            <h1>NotAuthorization</h1>
        );
}

export default NotAuthorization;