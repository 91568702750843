import React, {useContext, useEffect, useState} from 'react';
// import {CommandBar} from 'office-ui-fabric-react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';

import LenguajeContext from '../../../context/lenguaje/lenguajeContext';
import CookiesJS from 'js-cookie'
import { actualiza, consulta, RequestFetch } from '../../../utils/Requests/requests.Controller';
import FetchOAuth, { AuthConnect } from '../../../utils/auth/oauth.Controllers';

const MenuHeader =(props)=>{
    /************************
     * @arguments 
     ************************/
    

        const requestDomain = new RequestFetch("access/text");
        const OauthRequest = new RequestFetch(`OAuth/${props.inf?.usr}`);
        const nameClient = CookiesJS.get("nameClient");
        const datUsr = CookiesJS.get("dataUser");
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [controlsPage, setControlsPage] = useState({})
        const [textOnPages, setTextOnPages] = useState({})
        const [itemsLng, setItemsLng] = useState([])
        const [itemsFiles, setItemsFiles] = useState([])
    
        let db
            const requestInfo = indexedDB.open('textList',1)
            requestInfo.onsuccess =()=>{
                db = requestInfo.result
            }

            requestInfo.onerror = (error) =>{
                console.log('Error', error)
            }
            useEffect(() => {
                requestInfo.onsuccess =async()=>{
                    // alert('sdsdsd')
                    db = requestInfo.result
                    setTextOnPages(await consulta(db)) 
                }
            }, [])

        const idiom = async(id) =>{
            if(CookiesJS.get('idLng') != id){
                if (props.idLngW) {
                    props.idLngW(id)
                }
                if (props.idLng) {
                    props.idLng(id)
                }
                CookiesJS.set('idLng',id)
                if (Object.entries(props.inf).length>0) {
                    const response = await AuthConnect({
                        type: 'user',
                        user: props.inf.usr,
                        leng:id,
                        roll: props.inf.Domain,
                        tenant: props.inf.tenant
                    })
                }
                if (CookiesJS.get("dataUser")) {
                    let dataUser = JSON.parse(datUsr)
                    dataUser.idLanguage=id
                    const stringToJson = JSON.stringify(dataUser);
                    CookiesJS.set('dataUser', stringToJson)
                }

                const promise = await requestDomain.getItem({
                    param: {idLanguage: id}, 
                    tenant: nameClient
                });
                if (promise.data.captchadata && promise.data.captchadata.languageCode) {
                    promise.data.data.push({langCaptcha:promise.data.captchadata.languageCode})
                    if (props.langCaptcha) {
                        props.langCaptcha(promise.data.captchadata.languageCode)
                    }
                }
                getLenguaje(promise.data.data)
                if (promise) {
                    actualiza(db,{clave:'textSso' ,ssoPagesData:promise.data.data})
                }
            }
        }

        const files = async(path) =>{
            const miUrl = new URL(path);
            if (miUrl) {
                window.open(miUrl);
            }
        }
        

        useEffect(() => {
            
            let controlsPage = {};
            if (stateLanguage.lenguaje !=0) {
                stateLanguage.lenguaje.map((users) => {
                    if (users.path == 'controls') {
                        users.webcontrolspages.map((data) => {
                            controlsPage[data.idHtml] = data.languagewebcontrols[0];
                        })
                    }
                    
                    setControlsPage(controlsPage)
                })
            }else{
                if (Object.entries(textOnPages).length > 0) {
                    
                    textOnPages.ssoPagesData.map((users) => {
                        if (users.path == 'controls') {
                            users.webcontrolspages.map((data) => {
                                controlsPage[data.idHtml] = data.languagewebcontrols[0];
                            })
                        }
                        setControlsPage(controlsPage)
                    })
                }
            }
            // })
        }, [textOnPages, stateLanguage, props])

       const[commandItems, setComandItems]=useState([]);

        useEffect(() => {
            if (props.lng && Object.entries(props.lng).length > 0) {
                let arr = []
                props.lng.map(lng=>{
                    arr.push({key:lng.id, text:lng.name, idClient:lng.idClient, onClick:()=>idiom(lng.id)})
                })
                setItemsLng(arr)
            }
            if (props.files && Object.entries(props.files).length > 0) {
                let arr = []
                props.files.map(file=>{
                    arr.push({key:file.id, text:file.name, idClient:file.idClient, onClick:()=>files(file.path)})
                })
                setItemsFiles(arr)
            }
            setComandItems([
                {
                    key:'Ayuda',
                    className:'left-nav',
                    text:props.warn?(props.warn.OnClickHelp):(controlsPage.OnClickHelp?.value),
                    iconProps: {iconName: 'Info'},
                    subMenuProps:{
                        items:itemsFiles
                        // [
                        //     {key: 'Document', text: controlsPage.OnClickDocuments?.value, canCheck: true},
                        //     {key: 'Videos', text: controlsPage.OnClickVideos?.value},
                        //     {key: 'Images', text: controlsPage.OnClickImages?.value}
                        // ]
                    }
                }, 
                {
                    key:'Lenguaje', 
                    className:'right-nav',
                    text:props.warn?(props.warn.slcLanguage):(controlsPage.slcLanguage?.value),
                    iconProps: {iconName: 'Flag'},
                    subMenuProps:{
                        items:
                        itemsLng
                        // [
                        //     {key: 'Español', text: controlsPage.OnClickSpanish?.value, onClick:()=>idiom(1)},
                        //     {key: 'Ingles', text: controlsPage.OnClickEnglish?.value, onClick:()=>idiom(2)},
                        //     {key: 'Frances', text: controlsPage.OnClickFrançais?.value, onClick:()=>idiom(3)}
                        // ]
                    }
                }, 
            ])
        }, [controlsPage,props])
        
    /************************
     * @methods 
     ************************/
    
    /************************
     * @hooks 
     ************************/
        // useEffect(()=>{
        //     console.log(state)
        // },[state])
    /************************
     * @resturns 
     ************************/
        return(
            <div className="menu--user-cotainer">
                <CommandBar items={commandItems} className='clase-ran'/>
            </div>
        );
}

export default MenuHeader;