import React, {useEffect, useState, useContext, useRef} from "react";
import CookiesJS from "js-cookie";
import {Checkbox} from "@fluentui/react";
import {useParams, useNavigate} from "react-router-dom";
import {render} from "react-dom";

import SelectFile from "../components/controls/selectFile/selectFile.component";
import {RequestFetch, consulta} from "../utils/Requests/requests.Controller";
import ContainerBaseLoading from "../components/container/containerLoading/containerBaseLoading.component";
import {ConvertForm} from "../utils/validation/validateForm";
import ContainerCaptcha from "../components/container/contianerCaptcha/containerCaptcha.component";
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
import LenguajeContext from '../context/lenguaje/lenguajeContext';
import { PATH_SSO } from "../assets/settings/settinsrouters";

const RecoverUser = ({openTyC, idLng, siteKey, safeKey}) => {
  const DEFAULT_VALUES = {
    "externalEmail": ""
  };

  const {clientName} = useParams();
  const [domains, setDomains] = useState([]);
  const [domainSelected, setDomainSelected] = useState([]);
  const [accessAttributes, setAccessAttributes] = useState([]);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
  const [encryptedUser, setEncryptedUser] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const [captchaData, setCaptchaData] = useState(null);
  const [captchaSelected, setCaptchaSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {stateLanguage} = useContext(LenguajeContext);
  const [controlsPage, setControlsPage] = useState({})
  const [textOnPages, setTextOnPages] = useState({})
  const requestAttribute = new RequestFetch("Access/recoveryAttributes");
  const {idClient} = JSON.parse(CookiesJS.get("dataClient"));
  const [validator, setValidator] = useState(null)
  const [valDom, setValDom] = useState()
  const [fieldValues, setFieldValues] = useState(DEFAULT_VALUES);
  const [isDisable, setIsDisable] = useState(false)
  const script = document.createElement("script")
  const [renderCaptchaData, setRenderCaptchaData] = useState()
  const recaptchaRef = useRef();
  const [idiom, setIdiom] = useState('')
  const firstRender = useRef(true);

  let db
  const requestInfo = indexedDB.open('textList', 1)
  requestInfo.onsuccess = () => {
    db = requestInfo.result
  }

  requestInfo.onerror = (error) => {
    console.log('Error', error)
  }

  const showCapt = ()=>{
    const demoClasses = document.querySelectorAll('.grecaptcha-badge');
    demoClasses.forEach(element => {
        element.style.display=''
    });
  }
  useEffect(() => {
    requestInfo.onsuccess = async () => {
      db = requestInfo.result
      setTextOnPages(await consulta(db))
    }
    const loadDomains = async () => {
      const requestDomain = new RequestFetch("access/domain");
      const domains = await requestDomain.getById({id: idClient, tenant: clientName});
      if (domains.data && domains.data.data)
        setDomains(
          domains.data.data.map(
            domain => ({
              id: domain.id,
              text: domain.alias
            })
          )
        );
    }
    showCapt()
    loadDomains();
  }, [])

  useEffect(() => {
    let controlsPage = {};
    if (stateLanguage.lenguaje != 0) {
      stateLanguage.lenguaje.map((users) => {
        if (users.path == 'controls' || users.path == 'remember-user' || users.path == 'login-password') {
          users.webcontrolspages.map((data) => {
            controlsPage[data.idHtml] = data.languagewebcontrols[0];
          })
        }
        if (users.langCaptcha) {
          setIdiom(users.langCaptcha)
        }
        setControlsPage(controlsPage)
      })
    } else {
      if (Object.entries(textOnPages).length > 0) {
        textOnPages.ssoPagesData.map((users) => {
          if (users.path == 'controls' || users.path == 'remember-user' || users.path == 'login-password') {
            users.webcontrolspages.map((data) => {
              controlsPage[data.idHtml] = data.languagewebcontrols[0];
            })
          }
          if (users.langCaptcha) {
            setIdiom(users.langCaptcha)
            // setCaptchaLang(document.getElementById("recaptcha-container"), users.langCaptcha)
          }
          setControlsPage(controlsPage)
        })
      }
    }
    // })
  }, [textOnPages, stateLanguage])
  const navigate = useNavigate()

  useEffect(() => {
    if (domains.length>0) {
      _selectedDomain(domains[0]?.id)
      setValDom(domains[0]?.id)
    }
  }, [domains])

  useEffect(async () => {
    if (valDom) {
      
      // const loadOnChangeLang = async () => {
        const accessAttributesTemp = await _selectedDomain(valDom)
        if (captchaData) {
          setIsLoading(true);
          setCaptchaData(null);
          const dataForm = {
            externalEmail: fieldValues?.externalEmail,
            ...accessAttributesTemp.reduce((current, next) => {
              return {
                ...current,
                [next.id]: fieldValues?.[next.attributeScim]
              }
            }, {})
          }
          _requests.validateAttributes(dataForm);
        }
      // }
    }
    // if(valDom) loadOnChangeLang();
    
  }, [stateLanguage]);

  const setCaptchaLang = (recaptchaContainer, lang) => {
    if (recaptchaContainer != null) {
      const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');
      const currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();
      
      if (currentLang !== lang && iframeGoogleCaptcha != null) {
        iframeGoogleCaptcha.setAttribute(
          "src",
          iframeGoogleCaptcha.getAttribute("src").replace(
            /hl=(.*?)&/,
            'hl=' + lang + '&'
            )
            );
      }
    }
  }

  const handleLangChange = (lang) =>{
    if (window.grecaptcha != undefined) {
      
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&hl=${lang}`;
      setTimeout(() => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.reset();
        });
      }, 1000); 
    }
  }

  useEffect(() => {
    if (safeKey == 3) {
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&hl=${idiom}`
      handleLangChange(idiom)
        setRenderCaptchaData(
          <div className="w10 d--flex c--flex-wc mg20-h">
          <div 
           id="recaptcha-container"
            ref={recaptchaRef}
              className="g-recaptcha" 
              data-sitekey={siteKey}
              data-size="invisible"
          ></div>
          </div>
        )
    }
    if (safeKey == 2) {

      if (firstRender.current) {
        // En la primera renderización, no hacemos nada
        firstRender.current = false;
        return;
      }else{
        script.src = `https://www.google.com/recaptcha/api.js?hl=${idiom}`
        // if (recaptchaRef.current) {
          // Recargar el captcha con el nuevo idioma
          // window.grecaptcha.reset(recaptchaRef.current);
          setCaptchaLang(document.getElementById("recaptcha-container"), idiom)
          setRenderCaptchaData(
            <div id="recaptcha-container" className="w10 d--flex c--flex-wc mg20-h">
                <div ref={recaptchaRef} className="g-recaptcha" data-sitekey={siteKey}></div>
            </div>
          )
      }
      // }
    }
    if (encryptedUser != null) {
    switch (safeKey) {
      case 1:
        setRenderCaptchaData(
          <div className='c--fit-cont mg-a'>
          <ContainerCaptcha
            dataCaptcha={{
              dataCaptcha: captchaData
            }}
            idLanguage={idLng ? idLng : languageId}
            userName={encryptedUser}
            role={domainSelected}
            isUserEncrypted={true}
            actionOnChange={_captchaOnChange}
            onUpdateCaptcha={_onUpdateCaptcha}
            order={controlsPage.lblSelectTheCorrectCaptchaImage?.value}
          />
        </div>)
        break;
  
      default:
        break;
    }
  }
    document.body.appendChild(script)
  }, [encryptedUser,idiom])

  const _onSubmit = (e) => {
    e.preventDefault();
    const dataForm = ConvertForm(e)
    // _validateForm(dataForm)
    if (encryptedUser) {
      // if (_validateForm(dataForm)) {
        _requests.recoverUser(dataForm);
      // }
    } else {
      _requests.validateAttributes(dataForm);
    }
    // }
  }

  const _selectedDomain = async (value) => {
    setValidator(value)
    setAccessAttributes([]);
    setDomainSelected(value);
    let accessAttributes = await requestAttribute.getItem({
      param: {
        idDomain: value
      },
      headerEx: {idLanguageUser: idLng},
      tenant: clientName
    });
    if (accessAttributes.data && accessAttributes.data.data) {
      setAccessAttributes(accessAttributes.data.data);
    }
    return accessAttributes.data.data;
  }

  const _handleTermsAndConditions = (event) => {
    if (validator) {
      setTermsAndConditionsAccepted(event.target.checked);
    }
  }

  const _requests = {
    validateAttributes: (formData) => {
      let dataForm = []
      for (let clave in formData) {
        if (formData[clave] === "") {
          delete formData[clave];
        }
      }
      setIsLoading(true);
      Object.entries(formData)
          .filter(([field]) => field !== "externalEmail")
          .map(([field, value]) => (dataForm.push({id: Number(field), value})))
         
      const attributes = JSON.stringify(
        dataForm.filter(item => !isNaN(item.id))
      );

      const paramsResponse = new URLSearchParams({
        attributes,
        // personalEmail: formData.externalEmail
      }).toString();

      const requestValidateAttribute = new RequestFetch(`Access/validateAttributes/${domainSelected}`);
      requestValidateAttribute.createItemParams({
        tenant: clientName,
        body: paramsResponse,
        headerEx: {idLanguageUser: idLng},
        ct: "encode",
        param: ''
      })
        .then(({data}) => {
          setIsDisable(true)
          setIsLoading(false);
          if (data.data) {
            if (data.captchadata) {
              setCaptchaData(data.captchadata);
            }
            setEncryptedUser(data.data.encryptedUserName);
            setLanguageId(data.data.idLanguage);
            if (safeKey != 1) {
              _requests.recoverUser(data.data.encryptedUserName);
            }
          }
        })
        .catch(_ => {
          setIsLoading(false);
          if (recaptchaRef.current) {
            window.grecaptcha.reset(recaptchaRef.current);
          }
        })
    },
    recoverUser: (formData) => {
      setIsLoading(true);
      let responseTok = ''
      const requestValidateAttribute = new RequestFetch(`Access/recoverUser/${domainSelected}`);
      if (safeKey) {
        switch (safeKey) {

            case 2:
                responseTok=window.grecaptcha.getResponse()
                break;

            case 3:
                window.grecaptcha.ready(()=> {
                    window.grecaptcha.execute(siteKey, {action: 'submit'}).then((token) => {
                        // captcha 3
                        const paramsBody = new URLSearchParams({
                          userName: formData,
                          responseToken:token
                          // personalEmail: formData.externalEmail
                        }).toString();
                        requestValidateAttribute.createItemParamsRecover({
                          tenant: clientName,
                          body: paramsBody,
                          ct: "encode",
                          param: "",
                          headerEx: {idLanguageUser: idLng}
                        })
                          .then(({data}) => {
                            setIsLoading(false);
                            let props = {
                              title: controlsPage.lblSuccessfulValidation?.value,
                              description: controlsPage.NotificationHasBeenSenToAlternateEmail?.value,
                              type: 'success',
                            }
                            _openModal(props)
                            navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
                          })
                          .catch(error => {
                            setIsLoading(false);
                            if(error.response){
                              let info = error.response.data.errors[0]
                              let datCapt = error.response.data.captchadata
                              let imgCaptcha={
                                idCaptcha: datCapt.idCaptcha,
                                imagesBase64:datCapt.imagesBase64,
                                nameImage:datCapt.nameImage
                              }
                              setCaptchaData(imgCaptcha)
                
                              let props = {
                                title: info.title,
                                description: info.code+' - '+info.detail,
                                type: 'danger',
                              }
                              _openModal(props)
                            }
                          })
                    });
                });
                break;
        
            default:
                break;
        }
      }
      const paramsBody = new URLSearchParams({
        userName: encryptedUser != null?encryptedUser:formData,
        ...safeKey==1?{captchaImage: captchaSelected}:null,
        responseToken:responseTok
        // personalEmail: formData.externalEmail
      }).toString();

      
      if (safeKey != 3) {
        requestValidateAttribute.createItemParamsRecover({
          tenant: clientName,
          body: paramsBody,
          ct: "encode",
          param: "",
          headerEx: {idLanguageUser: idLng}
        })
          .then(({data}) => {
            setIsLoading(false);
            let props = {
              title: controlsPage.lblSuccessfulValidation?.value,
              description: controlsPage.NotificationHasBeenSenToAlternateEmail?.value,
              type: 'success',
            }
            _openModal(props)
            navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
          })
          .catch(error => {
            setIsLoading(false);
            if(error.response){
              let info = error.response.data.errors[0]
              let datCapt = error.response.data.captchadata
              if (safeKey == 1) {
                let imgCaptcha={
                  idCaptcha: datCapt.idCaptcha,
                  imagesBase64:datCapt.imagesBase64,
                  nameImage:datCapt.nameImage
                }
                setCaptchaData(imgCaptcha)
              }

              let props = {
                title: info.title,
                description: info.code+' - '+info.detail,
                type: 'danger',
              }
              _openModal(props)
            }
          })
      }
    }
  }
  const _openModal = (props) => {
    render(<ModalFluent
        props={props}
        actionOnClick={_ => {
        }}
        reload={false}
      />,
      document.getElementById("modal")
    );
  }

  const _captchaOnChange = (data) => {
    setCaptchaSelected(data);
  }

  const _onUpdateCaptcha = (data) => {
    setCaptchaData(data);
  }

  const _changeInputField = (field, event) => {
    setFieldValues(current => {
      return {
        ...current,
        [field]: event.target.value
      }
    })
  }

  return (
    <>
      <form
        onSubmit={_onSubmit}
        className="container--flex c--flex-hi c--flex-dc c--flex-f1 w10"
      >
        <h3 className='mg-tp15 clr--dark-II'>{controlsPage.lblRememberUser?.value}</h3>

        <SelectFile
          name="role"
          options={domains}
          seletVal={(e) => {
            _selectedDomain(domains[0]?.id);
            setValDom(domains[0]?.id)
          }}
          defaultValue={null}
          className="drop-style"
          placeholder={domains[0]?.text} 
          defaultValueName="Seleccione una opción"
          defaultKey={domains[0]?.id}
          disabled={true}
        />
        {/* <div key="dynamic-input-email" className="inputFile--container">
          <input
            type="text"
            name="externalEmail"
            placeholder={controlsPage.lblAlternateEmail?.label}
            defaultValue={fieldValues?.externalEmail || ""}
            onChange={value => {
              _changeInputField("externalEmail", value)
            }}
            disabled={isDisable}
          />
        </div> */}
        {
          accessAttributes.map(attribute => (
            <div key={`dynamic-input-${attribute.id}`} className="inputFile--container">
              <input
                type="text"
                id={attribute.id}
                name={attribute.id}
                placeholder={attribute.name}
                defaultValue={fieldValues?.[attribute?.attributeScim] || ""}
                onChange={event => {
                  _changeInputField(attribute.attributeScim, event)
                }}
                disabled={isDisable}
              />
            </div>
          ))
        }
        <br/>

        {renderCaptchaData && (
          renderCaptchaData
        )}

        <Checkbox
          defaultChecked={false}
          label={
            <label>{controlsPage.lblThisProcessRequiresTheAcceptanceOf?.value} 
            <a className="hyperText-TyC" onClick={()=>openTyC(controlsPage.urlTermsCondition?.value)}>
              {' '+controlsPage.urlTermsCondition?.value}
            </a>
            </label>
          }
          onChange={_handleTermsAndConditions}
        />
        <section className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
          <button className={
            `button--default 
              ${!termsAndConditionsAccepted && 'button--disabled'} 
              ${termsAndConditionsAccepted && 'button--primary'} 
              `}
                  type="submit"
                  disabled={!termsAndConditionsAccepted}
          >
            {captchaData ? controlsPage.smtSend?.value : controlsPage.smtValidate?.value}
          </button>
          <button className="button--default button--second" type="button"
                  onClick={() => navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginForm`)}>
            {controlsPage.smtCancel?.value}
          </button>
        </section>
      </form>
      {isLoading && <ContainerBaseLoading/>}
    </>
  );
};
export default RecoverUser;
