//****Librarys ****//
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CookieJS from 'js-cookie';

//*****Componnents*****//
import ButtonFloat from '../components/tools/buttonFloat/buttonFloat.component';
import SelectFile from '../components/controls/selectFile/selectFile.component';
import InputFile from '../components/controls/inputFile/inputFile.component';

//*****Methods*****//
import {ConvertForm} from '../utils/validation/validateForm';
import {consulta, RequestFetch} from '../utils/Requests/requests.Controller';

//*****Context*****//
import AuthContext from '../context/auth/authContext';
import { CHLoginUser } from '../hooks/CHLoginUser';
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
import { AuthConnect } from '../utils/auth/oauth.Controllers';
import { render } from '@testing-library/react';
import ContainerBaseLoading from '../components/container/containerLoading/containerBaseLoading.component';
import LenguajeContext from '../context/lenguaje/lenguajeContext';
import { PATH_SSO, SSO_URI } from '../assets/settings/settinsrouters';
import { FontIcon } from '@fluentui/react';
import InputFileFluent from '../components/controls/inputFile/inputFileFluent';
/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const LoginUser = (props) =>{ 

    

    
    /************************
     * @arguments 
     ************************/
        const {getUser, error, getError} = useContext(AuthContext);
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const navigate = useNavigate();

        const [valUser, setValUser] = useState('');
        const [valRoll, setValRoll] = useState();
        const [stateModal, setStateModal] = useState(false);
        const { clientName } = useParams();
        const [dataError, setDataError] = useState(null)
        const [redirect, setRedirect] = useState(false)
        const [reload,setReload] = useState(false)
        const [messageError, setMessageError] = useState(false)
        const [isLoad, setIsLoad] = useState(false)
        //*** Data properties ***//
        const requestDomain = new RequestFetch('access/domain');
        // Variables de implementacion de textos -------------------------------------------------------------------------------------
        const [errorCode, setErrorCode] = useState('')
        const [controlsPage, setControlsPage] = useState({});
        const [showProfile, setShowProfile] = useState(props.showProfile)
        
        const [textOnPages, setTextOnPages] = useState({})


        const indexedDB = window.indexedDB
        let db

        const hideCapt = ()=>{
            const demoClasses = document.querySelectorAll('.grecaptcha-badge');
            demoClasses.forEach(element => {
                element.style.display='none'
            });
        }

        useEffect(() => {
            const requestInfo = indexedDB.open('textList',1)
            const cleanup = () => {
                requestInfo.onsuccess = null;
                // Cancelar operaciones asincrónicas en consulta() si es necesario
            };
            hideCapt()
            requestInfo.onsuccess =async()=>{
                // alert('sdsdsd')
                // length para enviar a home si no tiene
                db = requestInfo.result
                if (db.objectStoreNames.length>0) {
                    setTextOnPages(await consulta(db)) 
                }else{
                    redirectHome()
                }
            }
            return cleanup;
        }, [])
        
        

        // ---------------------------------------------------------------------------------------------------------------------------
        

    /************************
     * @methods 
     ************************/

        const [domain, setDomain] = useState('');
        const [selectDom, setSelectDom] = useState([]);
        const [message, setMessage] = useState({})
        CHLoginUser(requestDomain, 1)
        .then(data =>{
            setDomain(data);
        })
        
        const actionOnClick=()=>{
            if (errorCode != '') {
                props.errorUsr(false)
            }
            redirectResetPassword()
            
        }     
        const actionConfirm=()=>{
           setDataError(null)
           setStateModal(false)
        }
        const MethodsFetch = {
            updateContext:async(e)=>{
                e.preventDefault();
                let userForm = ConvertForm(e)
                if(userForm.user == ""){
                    setMessageError(true)
                }
                userForm = Object.assign(userForm,{roll:valRoll,user:valUser})
                props.inf({usr:userForm.user, Domain:userForm.roll, idLng:userForm.idLanguage, tenant:clientName})
                getUser(userForm);
                setIsLoad(true)
                const response = await AuthConnect({
                    type: 'user',
                    user: userForm.user?userForm.user:' ', 
                    roll: userForm.roll,
                    tenant: clientName,
                    leng:props.idLng
                })

                if(response.status != 200){
                    if (response.errors) {
                        response.errors.map(error=>{
                            if (error.code == 'FI-0117' || error.code == 'FI-0114') {
                                setRedirect(true)
                            }
                            let info ={
                                title: error.title,
                                description: error.code + ': ' + error.detail,
                                type:'danger'
                            }
                            setDataError(info)
                            // setStateLoading(response)
                            setIsLoad(false)
                            setStateModal(true);
                            if (error.code == 'FI-0110') {

                                let info ={
                                    title: error.title,
                                    description: error.code + ': ' + error.detail + ' ',
                                    type:'danger'
                                }
                                setDataError(info)
                                setErrorCode(error.code)
                            }
                            if (error.code == 'FI-0118') {
                                setReload(true)
                            }
                        })
                    }
                    
                }else if(response){
                    userForm = Object.assign(userForm,{
                        dataCaptcha: response.data.captchadata,
                        idLanguage: response.data.data.idLanguage,
                        acceptedTerms: response.data.data.acceptedTerms,
                        roll:response.data.data.idDomain,
                        user:response.data.data.username
                    })
                    getUser(userForm);
                    const stringToJson = JSON.stringify(response.data.data);
                    CookieJS.set('dataUser', stringToJson)
                    if(response.data.data.isLocked === true ){
                        setIsLoad(false)
                        navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/IsLocked`);
                    }
                    navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginPassword`);
                    
                }

                //navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginPassword`);
            }
        }
        
        const redirectHome = () =>{
            window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`;
        }
 
        const redirectResetPassword = () => {
            setRedirect(false)
            if (errorCode != '') {
            if(errorCode=='FI-0110'){
            return
            }else {
            navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/ResetPassword`);
            }}
        }
        const redirectResetUser = () => {
            navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/RecoverUser`);
        }
        
    /************************
     * @hooks 
     ************************/
        //const [promiseDomain, setPromiseDomain] = useState(requestDomain.getById({
        //    id: 1
        //}))


        useEffect(() => {
            props.textBsr(false)
            // const response = Requests.getById({id:'', tenant: clientName });
            // response.then(data=>{
            let controlsPage = {};
            if (stateLanguage.lenguaje !=0) {
                // alert('enytfddf2')
                stateLanguage.lenguaje.map((users) => {
                    if (users.path == 'login-form' || users.path == 'verification-challenge') {
                        users.webcontrolspages.map((data) => {
                            controlsPage[data.idHtml] = data;
                        })
                    }
                    if (users.path == 'login-password' || users.path == 'verification-challenge') {
                        users.webcontrolspages.map((data) => {
                            controlsPage[data.idHtml] = data;
                        })
                    }
                    setControlsPage(controlsPage)
                })
            }else{
                if (Object.entries(textOnPages).length > 0) {
                    
                    textOnPages.ssoPagesData.map((users) => {
                        if (users.path == 'login-form') {
                            users.webcontrolspages.map((data) => {
                                controlsPage[data.idHtml] = data;
                            })
                        }
                        if (users.path == 'login-password') {
                            users.webcontrolspages.map((data) => {
                                controlsPage[data.idHtml] = data;
                            })
                        }
                        if (users.path == 'home') {

                            users.webcontrolspages.map((data) => {
    
                                controlsPage[data.idHtml] = data;
    
                            })
    
                        }
                        setControlsPage(controlsPage)
                    })
                }else{
                    navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
                }
            }
            // })
        }, [textOnPages, stateLanguage])

        useEffect(()=>{
            if(!props.idLng){
                navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
            }
        },[stateModal])

        useEffect(()=>{
            if(domain){
                let dom = [];
                for(let i = 0; i<domain.length; i++){
                    let domArray = { id: domain[i].id, text: domain[i].alias }
                    dom.push(domArray);
                    setSelectDom(dom);
                    if (showProfile == false) {
                        setValRoll(dom[0].id)
                    }
                    //console.log(selectDom);
                }
            }
        },[domain])
    /************************
     * @resturns 
     ************************/
    return(
        <>
            {
            Object.entries(controlsPage).length > 0?
                <>
                <div id="modal"></div>
                    {isLoad?
                        <ContainerBaseLoading />
                        :''
                    }
                    {stateModal? 
                        <ModalFluent
                        props={dataError}
                        confirm={()=>actionConfirm}
                        actionOnClick={actionOnClick}
                        objectRequest={()=>{actionOnClick();redirectResetPassword(); setStateModal(false)}}
                        redirect={redirect}
                        cancelIcon={errorCode != ''?true:false}
                        reload={reload}
                        />
                    : ''}
                    <div className="login-user">
                    <label>
                    {controlsPage.lblEnterUserName?.languagewebcontrols[0].value}
                    </label>
                    <form onSubmit={MethodsFetch.updateContext} className="container--flex c--flex-hi c--flex-dc c--flex-f1 w10">
                        <InputFileFluent
                            name="user"
                            placeholder={controlsPage.txtUserName?.languagewebcontrols[0].value}  
                            onChange={(e)=>{setValUser(e); setMessageError(false); setStateModal(false)}}
                            maxLength={controlsPage.txtUserName.maxlength}
                            minLength={controlsPage.txtUserName.minlength}
                            onMessage={(e)=>{setMessageError(e)}}
                            className="InputFileFluent"
                            // {...showProfile?null:{suffix:`@${selectDom[0]?.text}`}}
                        />
                        {messageError?
                            <div className='message-error-fluent'>
                                <p className='paragraf-error'>{controlsPage.txtUserName?.languagewebcontrols[0].infoMessage}</p>
                            </div>
                            :''}
                        {/* <SelectFile 
                            msgError={controlsPage.txtUserName?.languagewebcontrols[0].infoMessage}
                        /> */}
                        {showProfile?<SelectFile 
                            name="roll"
                            options={selectDom}
                            placeholder={controlsPage.slcProfile?.languagewebcontrols[0].value}
                            seletVal={(e)=>{setValRoll(e); setStateModal(false)}}
                            // defaultKey={selectDom[0]?.id}
                        />:''}
                        
                        {<a onClick={() => redirectResetUser()}  className="hipertext hipertext--primary mg10-h" >
                            {controlsPage.lblForgotYourUser?.languagewebcontrols[0].value}
                        </a>}

                        <section className="c--flex-f1 container--flex c--flex-wc c--flex-hc" >
                            <button className="button--default button--primary" type="submit">
                            {controlsPage.smtNext?.languagewebcontrols[0].value}
                            </button> 
                        </section>
                    </form>
                    </div>
                    {/* <div className="hipertext hipertext--primary-lft d--flex c--flex-hc">
                    <FontIcon onClick={()=>navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`)} aria-label="Compass" iconName="Permissions" className={classNames.deepSkyBlue} />
                    <a onClick={()=>navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`)} className="hipertext hipertext--primary-lft mg5-w" >
                            {controlsPage.OnClickLoginOptions?.languagewebcontrols[0].value}
                    </a>
                    </div> */}
                    {/* <ButtonFloat 

                        icon={} onClick={()=>navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`)} 
                        // type={1} icon="icon-key" typef="btnFloat-footer" text="Opciones de inicio de sesión" 
                        type={1} typef="btnFloat-footer" text={controlsPage.OnClickLoginOptions?.languagewebcontrols[0].value}
                    /> */}
                </>
        :
            <ContainerBaseLoading/>
            }
        </>
    );
}
        

export default LoginUser;