import qs from 'qs'

import {SERVISE_URI} from '../../assets/settings/settinsrouters'

const url = `${SERVISE_URI}api/OAuth`
const appKey = 'BSR SSO'

const OauthModels = ({type, user, roll, leng, credential, accessToken, tenant, acceptedTyC, captchaImage, responseToken})=>{
    const headers = {
        'Basic':{
            'Accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'tenant': tenant,
            'idLanguageUser':leng
        },
        'Oauth':{
            'Accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${credential}`,
            'acceptedTyC': acceptedTyC,
            'tenant': tenant,
            'idLanguageUser':leng
        }
    }

    const TYPES_OAUTH = {
        'user':{
            method: 'post',
            url: `${url}/${user}`,
            headers: headers['Basic'],
            params:{
                'idDomain': roll,
                'idLanguage':leng
            },
            data: qs.stringify({
                'client': appKey
            })
        },
        'token':{
            method: 'post',
            url: `${url}/${type}`,
            headers: headers['Oauth'],
            data: qs.stringify({
                'client': appKey,
                'idDomain': roll,
                'captchaImage': captchaImage,
                'responseToken':responseToken
            })
        },
        'introspect':{
            method: 'post',
            url: `${url}/${type}`,
            headers: headers['Basic'],
            params:{
                'token': accessToken
            },
            data: qs.stringify({
                'client': 'BSR SSO',
                'tenant': tenant
            })
        }
    }

    return TYPES_OAUTH[type]
}

export default OauthModels