/* #region Library*/
import CookiesJS from 'js-cookie'
import React, { useEffect, useState } from 'react'

import { DefaultButton, Panel, PanelType } from '@fluentui/react/lib/Panel';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { useBoolean } from '@fluentui/react-hooks';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
// import { Route } from 'react-router-dom'
/* #endregion */

/* #region Components*/
import ContainerBaseLoading from '../components/container/containerLoading/containerBaseLoading.component'
import ContainerBaseLogin from '../components/container/containerLogin/containerBaseLogin.component'
import MenuHeader from '../components/navigation/menuHeader/menuHeader.component'
import RouterPublic from '../components/routers/public/public.router'
import RouterPrivate from '../components/routers/private/private.router'
import { RequestFetch } from '../utils/Requests/requests.Controller';
import Warn from '../components/container/containerWarning/containerWarn';
/* #endregion */

/* #region Layouts*/
import Auth from '../layouts/auth'
import AvisoError from '../components/container/containerError/avisoError';
import IsLocked from '../layouts/isLocked'
import LoginUser from '../layouts/loginUser'
import LoginCode from '../layouts/loginCode'
import LoginQr from '../layouts/loginQr'
import LoginPassword from '../layouts/loginPassword'
import NotAuthorization from '../layouts/notAuthorization'
import ResetUser from '../layouts/resetUser'
import ResetPassword from '../layouts/resetPassword'
import RecoverPassword from '../layouts/RecoverPassword';
import SupportPassword from '../layouts/SupportPassword';
/* #endregion */

/* #region Methods*/
import FetchOAuth, { AuthConnect } from '../utils/auth/oauth.Controllers'
/* #endregion */

/* #region Context*/
import AuthState from '../context/auth/authState'
import LenguajeState from '../context/lenguaje/lenguajeState'
/* #endregion */

/* #region Setting*/
import RecoverUser from '../layouts/RecoverUser';
import { BSR_URI, PATH_SSO, SSO_URI } from '../assets/settings/settinsrouters'
import { Fragment } from 'react';
import LenguajeContext from '../context/lenguaje/lenguajeContext';
import VerificationChallenge from '../layouts/verificationChallenge';
/* #endregion */

/* #region File*/
/* #endregion */

/*****************
*****************
* @returns copomnent Routers
*****************
*****************/

const RouterViews = (props) => {
    /************************
     * @arguments 
     ************************/
     const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
     const [stateValToken, setStateValToken] = useState(false);
     const [verificationLink, setVerificationLink] = useState(false);
     const [isDisbleUsr, setIsDisbleUsr] = useState(true)
     const [cont, setCont] = useState(true)
     const { clientName } = useParams();
     const [txtTitle, setTxtTitle] = useState('')
     const dataUser = CookiesJS.get('dataUser')
     const nameClient = props.props;
     CookiesJS.set('nameClient', nameClient);
     const Requests = new RequestFetch(`access/client`);
     const introspect = new RequestFetch(`OAuth/introspect`);
     const requestTyC= new RequestFetch ('TermCondition')
     const [clientD, setClientD] = useState('')
     const [rendOpt, setRendOpt] = useState()
     const requestLang = new RequestFetch(`Languages`)
     const [theme, setTheme] = useState(null);
     const [langCaptcha, setLangCaptcha] = useState('')

     //const userData = JSON.parse(dataUser)
     const [itemTyC, setItemTyC] = useState([])
     const [idiom, setIdiom] = useState({})
     const [isForPass, setIsForPass] = useState(null)
    const [infUsr, setinfUsr] = useState({})
    const [idLng, setIdLng] = useState()
    const [itemFiles, setItemFiles] = useState({})
    const [validGetLng, setValidGetLng] = useState(false)
    const [hideTextBsr, setHideTextBsr] = useState(false)
    const [textSupport, setTextSupport] = useState({})
    const [safeKey, setSafeKey] = useState()
    const [siteKey, setSiteKey] = useState()
    const [showProfile, setShowProfile] = useState(false)

    useEffect(() => {
        if (CookiesJS.get('idLng')) {
            setIdLng(CookiesJS.get('idLng'))
        }
    }, [CookiesJS.get('idLng'),])
    
useEffect(() => {
    const style = document.documentElement.style
    if(theme){
           style.setProperty('--color-primary', `#${theme.primaryColor}`)
           style.setProperty('--color-light', `#${theme.ligthColor}`)
           style.setProperty('--color-semilight', `#${theme.semiligthColor}`)
           style.setProperty('--color-semidark', `#${theme.semidarkColor}`)
           style.setProperty('--color-dark', `#${theme.darkColor}`)
           style.setProperty('--color-hover', `#${theme.darkColor}`)
           style.setProperty('--font-primary', `${theme.primaryFont}`)
    }
}, [theme])

     
     
     
    /************************
     * @methods 
     ************************/

    // const DBDeleteRequest = window.indexedDB.deleteDatabase("textList");

    // DBDeleteRequest.onerror = (event) => {
    // console.error("Error deleting database.");
    // };

    // DBDeleteRequest.onsuccess = (event) => {
    // console.log("Database deleted successfully");
    // };

     let db
     const requestInfo = indexedDB.open('textList',1)
     requestInfo.onsuccess =()=>{
         db = requestInfo.result
     }

     requestInfo.onerror = (error) =>{
         console.log('Error', error)
     }

     requestInfo.onupgradeneeded = (e) =>{
         db = e.target.result

         const objetStore = db.createObjectStore('text',{
             keyPath:'clave',
             autoIncrement: true
         })
     }

    // useEffect(() => {
    //     requestInfo.onsuccess =()=>{
            // setCont(false)
    //     }
    // }, [])

    const addData = (data) =>{
        if (db) {
            if (db.objectStoreNames.length>0) {
                const transaction = db?.transaction(['text'],'readwrite')
                const objectStore = transaction?.objectStore('text')
        
                const request = objectStore?.add(data)
            }else{
                const DBDeleteRequest = window.indexedDB.deleteDatabase("textList");
                
                DBDeleteRequest.onerror = (event) => {
                    console.error("Error deleting database.");
                };
                
                DBDeleteRequest.onsuccess = (event) => {
                    console.log("Database deleted successfully");
                };
                window.location.reload()
            }
        }
    }
     
    const enlace = window.location.href;
    useEffect(() => {
        let posicion = enlace.includes("verification-link");
            setVerificationLink(posicion);
    }, [enlace])
    
    const getTyC = async()=>{
        if (idLng) {
            // const res = await requestTyC.getItem({tenant:nameClient,param:{idClient:userData.client,idLanguage:userData.idLanguage}})
            const res = await requestTyC.getItem({tenant:nameClient,param:{idLanguageUser:idLng}})
            setItemTyC(res.data.data)
        }

    }
    const errorUsr = (data) => {
        setIsDisbleUsr(data)
    }

    const isForPassFunc = (data) => {
        setIsForPass(data)
    }
    /************************
     * @hooks 
     ************************/
    initializeIcons();

    useEffect(async() => {
        if(nameClient){
            const response = await Requests.getById({id:'',tenant:nameClient });
            // if (cont) {
                addData({clave:'textSso' ,ssoPagesData:response.data.ssoPagesData})
                setTextSupport(response.data.ssoPagesData)
                setCont(false)
                setShowProfile(response.data.showProfiles)
                const {captchadata} = response.data
                
                // setIdLng(response.data.idClientLanguage)
                if (captchadata) {
                    setSafeKey(captchadata.idCaptcha)
                    if (captchadata.siteKey) {
                        setSiteKey(captchadata.siteKey)
                        setLangCaptcha(captchadata.languageCode)
                    }
                }
            // }
            const stringToJson = JSON.stringify(response.data.data);
            CookiesJS.set('dataClient', stringToJson);
            setTheme(response.data.data);
            const idClient=response.data.data.idClient
            setClientD(idClient)
            if (validGetLng) {
                const lang = await requestLang.getItem({
                    id:'',
                    tenant:nameClient,
                    param: {idClient:idClient},
                    headerEx: {idLanguageUser:idLng}
                })
                setIdiom(lang.data.data)
            }
        }
    }, [idLng,validGetLng,])

    useEffect(async() => {
        if (clientD) {
        const fileResponse = {
            idLanguage:idLng,
            isToAdmin:false,
        };
        const requestFiles= new RequestFetch (`Access/file/${clientD}`);
        const dataResponse = await requestFiles.getItem({
        param: fileResponse,
        tenant: nameClient,
        ct:'json',
        reload:true,
        cancelIcon:true
        })
        setItemFiles(dataResponse.data.data)
        }
    }, [idLng,clientD,])
    

    // useEffect(async () => {
    //     const token = CookiesJS.get('token')
    //     if (token) {
    //         const client = CookiesJS.get('nameClient')
    //         let isvalid = await FetchOAuth({
    //             accessToken: token,
    //             type: 'introspect',
    //             tenant: client,
    //             leng:idLng
    //         })
    //         if (isvalid.data.data) {
    //             if(isvalid.data.data.data.active == 'true'){
    //                 const { showAdmin } = JSON.parse(dataUser)
    //                 if(showAdmin){
    //                     window.location.href = `${BSR_URI}${nameClient}/administration`
    //                 }else{
    //                     window.location.href = `${BSR_URI}${nameClient}/user`
    //                 }
    //             }else{
    //                 let text = window.location.href
    //                 const url = text.split("?");
    //                 const myArray = url[0].replace(SSO_URI,'')
    //                 const suport = myArray.split("/");
    //                     CookiesJS.remove('token')
    //                 if (suport[suport.length -1] == 'support-password') {
    //                     setStateValToken(true)
    //                     // window.location.href=`${SSO_URI}/${nameClient}/home`
    //                     // window.location.href=text
    //                 }else{
    //                     window.location.href = `${SSO_URI}/${nameClient}/home`
    //                 }
    //             }
    //         }else{
    //             CookiesJS.remove('token')
    //             window.location.reload()
    //         }
    //     } else {
    //         setStateValToken(true)
    //     }
    // }, [])

    const infoForChallenge = {
        idLng:idLng,
        isDisbleUsr:isDisbleUsr,
        isForPass:isForPass, 
        rendOpt:rendOpt
    }
    
    /************************
     * @resturns 
     ************************/
    return (
        <>
        {theme === null?
            <ContainerBaseLoading />
            :
            <LenguajeState>
                <ContainerBaseLogin
                    imageBackground={theme.imageBackgroundPath}
                    openPanel={()=>{getTyC();openPanel()}}
                    header={
                        <MenuHeader langCaptcha={(e)=>setLangCaptcha(e)} lng={idiom} inf={infUsr} idLng={(e)=>setIdLng(e)} files={itemFiles}/>
                    }
                    verificationLink={verificationLink}
                    hideTextBsr={hideTextBsr}
                    valid={(e)=>setValidGetLng(e)}
                    logoPath={theme.logoPath}
                    main={
                        <>
                            <AuthState>
                                <div id="modal"></div>
                                {/* <img src={theme.logoPath} alt="LOGO"/> */}
                                <Router>
                                    <Routes>
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/LoginForm`} element={<LoginUser idLng={idLng} errorUsr={(e)=>{errorUsr(e)}} inf={(e)=>{setinfUsr(e)}} showProfile={showProfile} textBsr={(e)=>setHideTextBsr(e)}/>} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:nameClient/home`} element={<Auth idLng={(e)=>setIdLng(e)} validLng={(e)=>setValidGetLng(e)} textBsr={(e)=>setHideTextBsr(e)}/>} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/LoginQR`} element={<LoginQr />} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/LoginCode`} element={<LoginCode />} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/LoginPassword`} element={<LoginPassword idiom={langCaptcha} idLng={idLng} openPanel={()=>{getTyC();openPanel()}} textBsr={(e)=>setHideTextBsr(e)} safeKey={safeKey} siteKey={siteKey}/>} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/ResetUser`} element={<ResetUser />} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/ResetPassword`} element={<ResetPassword idLng={idLng} isDisable={isDisbleUsr} isForPass={isForPass} rendOpt={rendOpt} textBsr={(e)=>setHideTextBsr(e)}/>} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/support-password`} element={<SupportPassword validLng={(e)=>setValidGetLng(e)} idLng={(e)=>setIdLng(e)} errorUsr={(e)=>{errorUsr(e)}} forPass={(e)=>{isForPassFunc(e)}} controlsPage={textSupport}/>} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/multifactor-authentication`} element={<VerificationChallenge forChallenge={infoForChallenge} textBsr={(e)=>setHideTextBsr(e)}/>} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/NotAuthorization`} element={<NotAuthorization />} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/IsLocked`} element={<IsLocked />} />
                                        <Route exact path={`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/:clientName/RecoverUser`} element={<RecoverUser langCap={langCaptcha} openTyC={(e)=>{getTyC();openPanel();setTxtTitle(e)}} idLng={idLng} safeKey={safeKey} siteKey={siteKey}/>} />
                                        <Route exact path="*" element={<AvisoError />} />
                                        {/* <Route exact path="/:clientName/verification-link" element={<Warn openPanel={()=>{getTyC();openPanel()}} key={'warn'}/>} /> */}
                                        <Route exact path="/:clientName/RecoverPassword" element={<RecoverPassword/>} />
                                    </Routes>
                                </Router>
                            </AuthState>
                        </>
                    }
                    footer={(e)=>{getTyC();openPanel();}}
                    titleTyC={(e)=>{setTxtTitle(e)}}
                />
                <Panel
                                isOpen={isOpen}
                                type={PanelType.custom}
                                headerText={txtTitle}
                                closeButtonAriaLabel="Close"
                                customWidth={PanelType.custom ? '420px' : undefined}
                                isHiddenOnDismiss={true}
                                onDismiss={dismissPanel}
                                key={txtTitle+'key'}
                            >      
                               <div> 
                                {/* <span>
                                    Ipsum in dolor proident nostrud aliqua commodo officia nisi. Consequat magna esse et aliqua aliqua in est mollit. Exercitation velit consequat laborum dolor pariatur eu enim tempor id exercitation culpa excepteur sint. Ad occaecat excepteur occaecat nisi qui veniam irure elit sit occaecat do. Ea sint cillum nostrud mollit nostrud exercitation nulla sit mollit ex minim. Consectetur cillum in sint anim officia. Qui sint aute proident magna aliquip ipsum ut est sint proident Lorem adipisicing.
                                </span> */}
                               {itemTyC.map(opt => 
                                        <>
                                            <ul key={opt.name+'Panel terminos y condiciones'}>
                                                <li key={opt.name}>
                                                    <a href={opt.url} key={'key'+opt.name} className="hipertext  clr--light" target='_blank'>{opt.name}</a>
                                                </li>
                                            </ul>
                                        </>
                                    )
                                } 
                               </div>                  
                            </Panel>
            </LenguajeState>
        }
        </>
    )
}


export default RouterViews;