    //****Librarys ****//
    import React, { useContext, useEffect, useState } from 'react';
    import { useNavigate, useParams } from 'react-router-dom';
    import CookieJS from 'js-cookie';

    //*****Componnents*****//
    import ButtonBlock from '../components/tools/buttonBlock/buttonBlock.component';

    //*****Context*****//
    // import LenguajeContext from '../context/lenguaje/lenguajeContext';

    //*****File*****//
    import IconData from '../assets/img/ico-datos.svg'

    //*****Helpers***//
    import { actualiza, RequestFetch } from '../utils/Requests/requests.Controller';
    import { HubConnectionBuilder } from '@microsoft/signalr';
    import ContainerBaseLoading from '../components/container/containerLoading/containerBaseLoading.component';
    import { PATH_SSO, SERVISE_URI } from '../assets/settings/settinsrouters';
    import LenguajeContext from '../context/lenguaje/lenguajeContext';


    /*****************
    *****************
    * @returns copomnent BDM-SSO
    *****************
    *****************/
     

    const Auth = (props) =>{
        const [cont, setCont] = useState(true)
        const [textOnPages, setTextOnPages] = useState()
        const indexedDB = window.indexedDB
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
        const [db, setDb] = useState()
        // useEffect(() => {
        
        // const requestInfo = indexedDB.open('textList',1)
        // requestInfo.onsuccess =()=>{
        //     setDb(requestInfo.result)
        // } 

        const openDB = () => {
            return new Promise((resolve, reject) => {
              const request = indexedDB.open('textList',1);
              
              request.onsuccess = (event) => {
                const db = event.target.result;
                resolve(db);
              };
              request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains("text")) {
                  db.createObjectStore("text", { keyPath: "clave" });
                }
              };
              request.onerror = (event) => {
                reject(event.target.error);
              };
            });
        };
        
    /************************
     * @arguments 
     ************************/
        
        const navigate = useNavigate();
        const { nameClient } = useParams();
        CookieJS.set('nameClient', nameClient);
        const url = "https://localhost:44379/identityVerificationHub/";
        //***Data Propertise***//
        const Requests = new RequestFetch(`access/client`);
        const [ connection, setConnection ] = useState(null);


    // Variables implementacion de textos --------------------------------------------------------------------------------------------

        const [controlsPage, setControlsPage] = useState({});

    // -------------------------------------------------------------------------------------------------------------------------------


    /************************
     * @methods 
     ************************/
    //  const connectToWebSocket = () => {
    //     setConnection(new HubConnectionBuilder().withUrl(url)
    //         //.withAutomaticReconnect()
    //         .build());
    //         console.log(connection)
    //         connection.onreconnected(connectionId => {
    //             getConnectionId()
    //         });
        
    //     connection 
    //       .start()
    //       .then(() => console.log('Connection started!'))
    //       .then(() => getConnectionId())
    //       .catch(err => console.log('Error while establishing connection :('));

    //       connection.on('ReceiveVerification', (message, isTrue) => {      
    //         console.log('ReceiveVerification', message);
    //       });
    // }
    const hideCapt = ()=>{
        const demoClasses = document.querySelectorAll('.grecaptcha-badge');
        demoClasses.forEach(element => {
            element.style.display='none'
        });
    }

    const getConnectionId = () => {
        connection.invoke('Getconnectionid').then(
            (data) => {
                // console.log('idConection', data);
                CookieJS.set('idConnection', data);
                // saveSocketConnection(data.data).then(response => {
                // console.log('save socket connection works', response);
                // });
            }
        );
    }

    // const saveSocketConnection = (idConection) =>{
    //     console.log(idConection)
    //     CookieJS.set('idConnection', idConection);
    //     return true;
    // }

    /************************
     * @hooks 
     ************************/
        // const {state} = useContext(LenguajeContext)

        useEffect(async() => {
            props.validLng(true)
            props.textBsr(false)
            let controlsPage = {};
            const response = await Requests.getById({id:'', tenant: nameClient, reload:true, cancelIcon:true });
            CookieJS.set('idLng',response.data.idClientLanguage)
            props.idLng(response.data.idClientLanguage)
                response.data.ssoPagesData.map((spd) => {
                    if (spd.path == 'home') {

                        spd.webcontrolspages.map((data) => {

                            controlsPage[data.idHtml] = data;

                        })

                    }
                })
                const stringToJson = JSON.stringify(response.data.data);
                const ssoPgs = JSON.stringify(response.data);

                CookieJS.set('dataClient', stringToJson);
                setControlsPage(controlsPage)
                // console.log(controlsPage)
                if (response.data.captchadata.languageCode) {
                    response.data.ssoPagesData.push({langCaptcha:response.data.captchadata.languageCode})
                }
                getLenguaje(response.data.ssoPagesData)
                // console.log(response.data.ssoPagesData)
                navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${nameClient}/LoginForm`)
                if (cont) {
                    // addData({clave:'textSso' ,ssoPagesData:response.data.ssoPagesData})
                }
                hideCapt()
        }, [])

        useEffect(async() => {
          if (stateLanguage.lenguaje != 0) {
            openDB().then((db) => {
            actualiza(db,{clave:'textSso' ,ssoPagesData:stateLanguage.lenguaje})
            let controlsPage = {};
            // const response = await Requests.getById({id:'', tenant: nameClient });
            
                stateLanguage.lenguaje.map((spd) => {
                    if (spd.path == 'home') {

                        spd.webcontrolspages.map((data) => {

                            controlsPage[data.idHtml] = data.languagewebcontrols[0];

                        })

                    }
                })
                // const stringToJson = JSON.stringify(response.data.data);
                const ssoPgs = JSON.stringify(stateLanguage.lenguaje);
                // CookieJS.set('dataClient', stringToJson);
                setControlsPage(controlsPage)
                // console.log(controlsPage)
                // getLenguaje(response.data.ssoPagesData)
                // console.log(response.data.ssoPagesData)
                if (cont) {

                    // addData({clave:'textSso' ,ssoPagesData:stateLanguage.lenguaje})

                }
            })
          }
        }, [stateLanguage, CookieJS.get('idLng')])
        
        
        // useEffect(async() => {

        //         const stringToJson = JSON.stringify(response.data.data);
        //         const ssoPgs = JSON.stringify(response.data);
        //         CookieJS.set('dataClient', stringToJson);

        //         if (cont == false) {
        //             cleanData(3)
        //             addData(ssoPgs)
        //         }
                
        // }, [])



// useEffect(() => {
//   if (textoos!=null) {
//     alert('si')

//     addData(textoos)
//   }
// }, [textoos])


        useEffect(() => {
            const newConnection = new HubConnectionBuilder()
            .withUrl(`${SERVISE_URI}identityVerificationHub/`)
            //.withAutomaticReconnect()
            .build();
            newConnection.onreconnected(connectionId => {
                getConnectionId()
            });

        setConnection(newConnection);
        }, [])

        useEffect(() => {
            if (connection) {
                connection.start()
                    .then(result => {
                        // console.log(result)
                        // console.log('Connected!');
                        getConnectionId()
                        connection.on('ReceiveVerification', (data) => {
                            // console.log(data)
                            // console.log('ReceiveVerification', data.result);
                        });
                    })
                    .catch(e => console.log('Connection failed: ', e));
            }
        }, [connection])

        // useEffect(() => {
        //     connectToWebSocket();
        // }, [])
        
    /************************
     * @resturns 
     ************************/
    return (
        <>
            {Object.entries(controlsPage).length > 0?
                <>
                    {/* <p>{controlsPage.lblPasswordChangeManager?.value}</p> */}
                <div className="container--flex c--flex-hi c--flex-dc w10">
                    <div className="df">
                        <h3 >{controlsPage.lblLoginOptions?.value}</h3>
                    </div>
                    <div className="w10">
                    <ButtonBlock onClick={()=>navigate(`/${PATH_SSO ? `${PATH_SSO}/` : ''}${nameClient}/LoginForm`)} icon={IconData}
                        text=
                        {controlsPage.OnClickFormLogin?.value}
                        />
                    {/* <ButtonBlock onClick={()=>navigate('/LoginqR')} icon={IconData}
                        text="Iniciar sesión con ingreso medio Código QR de lores impuso, consetetur sadipscing elitr."/>
                    <ButtonBlock onClick={()=>navigate('/LoginCode')} icon={IconData}
                        text="Iniciar sesión con ingreso medio de Código OTP lores impuso, consetetur sadipscing elitr."/> */}
                    </div>
                </div>
                </>
            :
            <ContainerBaseLoading/>
            }
        </>
    )
        
}

export default Auth;