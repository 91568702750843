import React, {useState, useEffect} from 'react'
import { FontIcon } from '@fluentui/react/lib/Icon';
import FluentUIReact from '@fluentui/react'
import { DefaultButton, Modal, IconButton, getTheme, mergeStyleSets, FontWeights } from '@fluentui/react'
import './modal.component.scss'
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { consulta } from '../../../utils/Requests/requests.Controller';

const ModalFluent = (props)=>{

  const [textOnPages, setTextOnPages] = useState({})
  const [controlsPage, setControlsPage] = useState({})
  let info ={
    title:props.props.title,
    description:props.props.description,
    type:props.props.type,
    txtButton:props.props.txtButton
  }
  const [confirm, setConfirm] = useState()
  const actionOnClick = (e) => {
    setConfirm(props.objectRequest())
    props.objectRequest()
    if (!props.disableClose) {
      setIsModalOpen(false);
    }
  }

  // useEffect(() => {
  //   props.actionOnClick(confirm)
  // }, [confirm])
  let db
  const requestInfo = indexedDB.open('textList',1)
  requestInfo.onsuccess =()=>{
      db = requestInfo.result
  }

  requestInfo.onerror = (error) =>{
      console.log('Error', error)
  }
  useEffect(() => {
      requestInfo.onsuccess =async()=>{
          // alert('sdsdsd')
          db = requestInfo.result
          setTextOnPages(await consulta(db)) 
      }
  }, [props])
  
  useEffect(() => {
    const controlsPage = {}
    if (Object.entries(textOnPages).length > 0) {
      textOnPages.ssoPagesData.map((users) => {
          if (users.path == 'controls') {
              users.webcontrolspages.map((data) => {
                  controlsPage[data.idHtml] = data.languagewebcontrols[0].value;
              })
          }
          setControlsPage(controlsPage)
      })
  }
  }, [textOnPages])
  

  const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
      if(Object.keys(props.props).length > 0){
        info.title = props.props.title
        info.description = props.props.description
        info.type=props.props.type
      setIsModalOpen(true)
      }
    }, [props])
    
  const hideModal = () => {
    if (!props.disableClose) {
      setIsModalOpen(false);
    }
    if(props.props.action){
      props.props.action()
    }
    if(info.type === 'success'){
      window.location.reload()
    }
    if (props.reload) {
      window.location.reload()
    }
    
  }
  const aceptModal = () => {
    if (!props.disableClose) {
      setIsModalOpen(false);
    }
    if (props.reload) {
      window.location.reload()
    }
    if (props.redirect) {
      props.actionOnClick(props.objectRequest())
    }
    if(props.props.action){
      props.props.action()
    }
  }
  const limitText = (text) => {
    if (text.length > info.description) {
      return text.slice(0, info.description) + '...';
    }
    return text;
  };

  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      {
        flex: '1 1 auto',
        color: theme.palette.neutralPrimary,
        backgroundColor: '#eaeaea',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '8px 12px 10px 20px',
        fontSize: '20px'
      },
    ],
    body: {
      
      minWidth:'520px',
      minHeight:'30px',
      flex: '4 4 auto',
      padding: '0px 0px 0px 0px',
      fontSize: '15px'
    },
    p: {
      padding: '40px 40px 40px 40px',
    },
  });
  const iconButtonStyles = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
  
  };

  const [typeModal, setTypeModal] = useState({})
  const typeM = {
    'success' : {
        error: false,
        title: 'Success',
        icon: 'icon-check',
        class: 'modal--icon-success'
    },
    'warning': {
        error: false,
        title: 'Warning',
        icon: 'icon-attention-alt',
        class: 'modal--icon-warning'
    },
    'danger': {
        error: true,
        title: 'Error',
        icon: 'icon-cancel',
        class: 'modal--icon-danger'
    },
    'default': {
        error: false,
        title: 'Default',
        icon: 'icon-lock-filled',
        class: 'modal--icon-default'
    },
}

useEffect(()=>{
  setTypeModal(typeM[info.type])
},[props])  
  return (
    <>
      <Modal
        titleAriaId="id"
        isOpen={isModalOpen}
        onDismiss={()=>hideModal}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <span id="id">{info.title}</span>
          {props.cancelIcon?'':<IconButton
          styles={iconButtonStyles}
            iconProps = {{iconName: 'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />}
          
        </div>
        <div className={`modal--icon ${typeModal.class}`}>
            
            <a className={`${typeModal.icon}`}/>
        </div>
        <div className="d--flex content-modal">
        <div className={`modal--body ${contentStyles.body}`}>
        
                 
        <p className={`${contentStyles.p} top-resp ${info.type === 'success' || info.type === 'warning'?'center-text':''}`}>
          {limitText(info.description)}
        </p>
        

        </div>
        {info.type === 'success' || info.type === 'danger'?
        <div className={`modal--content-btns pd ${contentStyles.body}`}>
            <button onClick={aceptModal} className="button--default button--primary br w10-resp button--default-resp-h">{controlsPage?.smtAccept?controlsPage?.smtAccept: info.txtButton? info.txtButton :'Aceptar'}</button>
        </div>
        :
        <div className={`modal--content-btns pd ${contentStyles.body}`}>
          <div className="d--flex w5 w10-resp dsp-flex c--flex-wsa">
            <button onClick={actionOnClick} className={`button--default button--primary w10-resp br button--default-resp-h`}>{controlsPage?.smtAccept?controlsPage?.smtAccept: info.txtButton? info.txtButton :'Aceptar'}</button>
            {props.cancelIcon?'':<button onClick={hideModal} className="button--default button--second w10-resp br ml2 button--default-resp-h">{controlsPage?.smtClose?controlsPage?.smtAccept: info.txtButton? info.txtButton :'Aceptar'}</button>}
          </div>
        </div>
        }
        </div>
      </Modal>
    </>
  );
};
export default ModalFluent