import React, { useState } from 'react'

const ButtonForm = (props) => {
    const [enableButton, setEnableButton] = useState(false)

    
    const actionButton = () =>{
        if (props.oneClick) {
            setEnableButton(true)
        }
    }
    const handleClick = () => {
        // const form = document.getElementById(props.formId);
        // if (form && enableButton == false) {
            console.log('form')
        // }
    };

  return (
    <div className={props.classNameDiv}>
        <button type="submit" 
            onClick={()=>{handleClick(); actionButton()}}
            className={`button--default--none ${props.className} ${enableButton? 'button--disabled':'button--primary'} `}
            // disabled={enableButton}
        >
            {props.text}
        </button>
    </div>
  )
}

export default ButtonForm;