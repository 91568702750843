/* #region librarys*/
import React from 'react';
import ReactDOM from 'react-dom';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import CookiesJS from "js-cookie";
/* #endregion */
import { SSO_URI, PATH_SSO } from './assets/settings/settinsrouters';


/* #region Style*/
import './assets/scss/index.scss';
import 'react-simple-keyboard/build/css/index.css';
/* #endregion */


/* #region Component*/
import RouterViews from './router/router';
/* #endregion */
//Saca nombre del cliente que se envia para consultar su información


const path_initial = SSO_URI+(PATH_SSO==''?PATH_SSO:'/'+PATH_SSO)
const location = window.location.href
const arrLocation = location.replace(path_initial,'').split('/')
const tenant =arrLocation[1]

/* #region App Rendering*/
ReactDOM.render(
  <RouterViews props={tenant}/>,
  document.getElementById('root')
);
/* #endregion */
