import React, {useReducer} from 'react'
import AuthReducer from './authReducer'
import AuthContext from './authContext'

const AuthState = (props)=>{
    const initialState = ''
    const initialError = ''

    const [state, dispatch] = useReducer(AuthReducer, initialState) 
    const [error, errordispatch] = useReducer(AuthReducer, initialError)

    const getUser = (user)=>{
        dispatch({
            type: 'GET_AUTH_USER',
            payload: user
        })
    }
    const getError =(error)=>{
        errordispatch({
            type: 'GET_AUTH_ERROR',
            payload: error
        })
    }

    return(
        <AuthContext.Provider value={{state, error, getUser, getError}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;   
