//****Librarys ****//
import React from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import dark from '../../../assets/img/img-oscura.svg'
import light from '../../../assets/img/img-clara404.svg'
import alert from '../../../assets/img/ico-alerta.svg'
import CookiesJS from 'js-cookie'
import { PATH_SSO, SSO_URI } from '../../../assets/settings/settinsrouters';

//*****Componnents*****//

//*****Methods*****//

//*****Context*****//

/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const AvisoError = () =>{ 

    const nameClient = CookiesJS.get("nameClient");

    /************************
     * @arguments 
     ************************/
     const iconClass = {
        fontSize: 50,
        height: 50,
        width: 50,
        margin: '50px 55px',
        'animation-name': 'animate',
        'animation-duration': '3s'
        };
    /************************
     * @hooks 
     ************************/
        
    /************************
     * @resturns 
     ************************/
        return(
            <>
            <div className='rat'>
                <div className="padd">
                    <img src={light} alt="luz" className=" light" />
                    <img src={dark} alt="luz" className=" dark" />
                </div>
                <div className="padd2">
                    <img src={alert} alt="alert" className="alert"/>
                    <p className="paragraf">Lo sentimos, no hemos podido encontrar la página que buscas.</p>
                        <a href={`${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${nameClient}/home`}>Ir al inicio</a>
            <br /><br /><br /><br />
                        <FontIcon aria-label="Compass" id="linetext" iconName="Mail" className='email'/>
                        <p id="linetext" className="linetext">
                            
                            &nbsp;Para mayor información puede escribir a <a href='mailto:soporte@bsr.com'>soporte@bsr.com.</a>
                        </p>
                    
                </div>
            </div>
                
            </>
        );
}

export default AvisoError;