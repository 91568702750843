//  export const BSR_URI = 'http://localhost:3500/'
//  export const SSO_URI = 'https://localhost:3400'
//  export const SERVISE_URI = 'https://bsr-sso-services.azurewebsites.net/'
//  export const SERVISE_URI = 'https://bext-sso.azurewebsites.net/'


// export const BSR_URI = window.BSR_APP_URI;
// export const SSO_URI = window.BSR_SSO_URI;
// export const SERVISE_URI = window.API_SERVICES_URI;

export const BSR_URI = window.__RUNTIME_CONFIG__.BSR_APP_URI;
export const SSO_URI = window.__RUNTIME_CONFIG__.BSR_SSO_URI;
export const SERVISE_URI = window.__RUNTIME_CONFIG__.API_SERVICES_URI;
export const PATH_APPLICATION = window.__RUNTIME_CONFIG__.PATH_APPLICATION;
export const PATH_SSO = window.__RUNTIME_CONFIG__.PATH_SSO;