//****Librarys ****//
import React, { useContext, useEffect, useState } from 'react';
// import { Checkbox/*, Stack*/ } from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';

import CookieJS from 'js-cookie';

//*****Componnents*****//
import ButtonFloat from '../components/tools/buttonFloat/buttonFloat.component';
import ContainerCaptcha from '../components/container/contianerCaptcha/containerCaptcha.component';
import ContainerBaseLoading from '../components/container/containerLoading/containerBaseLoading.component';
// import InputFile from '../components/controls/inputFile/inputFile.component';

//*****Methods*****//
import {EncodeB4/*, DecodeB4*/} from '../utils/encode/encode';
import { AuthConnect} from '../utils/auth/oauth.Controllers';
 
//*****Context*****//
import AuthContext from '../context/auth/authContext';

//*****Setting*****//
import {BSR_URI, PATH_SSO} from '../assets/settings/settinsrouters';
import { ConvertForm } from '../utils/validation/validateForm';

//*****helpers*****//
import { getLanguages } from '../helpers/languages/getLanguages';
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
//import { RequestFetch } from '../utils/Requests/requests.Controller';

/************************
*@arguments
*****************/
    //***Data Propertise***//
    //const requestsLanguages = new RequestFetch('Languages');
    //const [proLanguage, setProLanguage] = useState();

 /*****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const IsLocked = () =>{       
            
    /************************
     * @arguments 
     ************************/
        const {state, getError} = useContext(AuthContext)
        const navigate = useNavigate()
       
        const [stateLoading, setStateLoading] = useState(null)
        //const [valPass, setValPass] = useState('')
        const [dataError, setDataError] = useState()
        // let chktyc = Boolean;
        //const [language, setLanguage] = useState({
        //    data: []
        //});
        const { clientName } = useParams();
        const nameClient = CookieJS.get('nameClient');
        
    /************************
     * @methods 
     ************************/     
     
     const actionOnClick=()=>{}
        const Methods = {
            validateUser: async ()=>{
                if(state.user === '' || state === ''){
                    navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginForm`);
                }else{
                    const response = await AuthConnect({
                        type: 'user',
                        user: state.user, 
                        roll: state.roll,
                        tenant: nameClient
                    })
                    console.log(response)
                    const stringToJson = JSON.stringify(response);
                    if(response.errors){
                        getError(response.errors[0])
                        navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginForm`);
                    }
                    if(response.data.data.isLocked){
                        navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/IsLocked`);
                    }
                    else{
                        setStateLoading(response.data.data.userExist)
                        //setChktyc(response.data.data.acceptedTerms)
                        // chktyc = response.data.data.acceptedTerms
                        CookieJS.set('dataUser', stringToJson)
                        //setStateLoading(respose.data.data.user.active)
                        
                    }
                }
            },
            validatePass: async(e)=>{
                e.preventDefault()

                const dataForm = ConvertForm(e)
                const credential = EncodeB4({value: state.user, value2:dataForm.pass})
                const token = await AuthConnect({
                    type: 'token',
                    credential: credential, 
                    roll:1,
                    tenant: nameClient
                })
                if(token.data){
                    const Rolls = {
                        '0': 'User',
                        '1': 'Administration',
                        '2': 'User' 
                    }
                    const urlRoll = Rolls[state.roll] 
                    const dataToken = token.data.data.token.access_token;
                    const JsonDataToken = JSON.stringify(dataToken);
                    CookieJS.set('dataToken', JsonDataToken)
                    CookieJS.set('token', token.data.data.token.access_token)
                    getLanguages(dataToken, clientName)
                        .then(lng =>{
                            const jsonLng = JSON.stringify(lng);
                            CookieJS.set('languages', jsonLng);
                        })
                    setTimeout(() =>{
                        window.location.href = `${BSR_URI}${clientName}/${urlRoll}`
                    },1500);
                }else{
                    token.errors.map(error=>{
                        let info ={
                            title:error.title,
                            description:error.detail,
                            type:'danger',
                          }
                        setDataError(info)
                        })
                }
            }
        }

    /************************
     * @hooks 
     ************************/
         useEffect(()=>{
            Methods.validateUser();
         }, [])

         

    /************************
     * @resturns 
     ************************/
        if(!stateLoading){
            return(<ContainerBaseLoading/>)
        }
        return(
            <>
            {dataError?
            <ModalFluent
            props={dataError}
            actionOnClick={actionOnClick}
            />:
            ''}
                <ButtonFloat 
                    icon="icon-left-open hipertext--icon" 
                    href="/LoginForm"
                    type={1} 
                    typef="btnFloat-header" 
                    text="usuario@mail.com" 
                />
                <label>Cuenta de usuario Bloqueada</label>
                <form 
                    onSubmit={Methods.validatePass} 
                    className="container--flex c--flex-hi c--flex-dc c--flex-f1 w10"
                >

                <label>Opciones de validación de identidad</label>
                <a 
                    text="Codigo de verificacion al correo alterno"/>
                <a 
                    text="Responder preguntas de seguridad"/>
                <a  
                    text="Link de confirmación"/>

                    <ContainerCaptcha/>
                    <section className="c--flex-f1 container--flex c--flex-wc c--flex-he">
                        <button type="submit" className="button--default button--primary">
                            SIGUIENTE
                        </button> 
                    </section>
                </form>
            </>
        );
}

export default IsLocked;