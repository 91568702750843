//****Librarys ****//
import CookiesJS from 'js-cookie'
import React, { useContext,useEffect, useState } from 'react'
//import { PrimaryButton } from 'office-ui-fabric-react'
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DefaultButton, Panel, PanelType } from '@fluentui/react/lib/Panel';
//*****Componnents*****//

//*****File*****//
import { ReactComponent as IconLogo } from '../../../assets/img/ico-candado-abierto.svg'
import imgTop from '../../../assets/img/img-top.svg'
//*****Setting*****//
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FontIcon } from '@fluentui/react';
import { consulta,RequestFetch } from '../../../utils/Requests/requests.Controller';
import ContainerBaseLoading from '../containerLoading/containerBaseLoading.component';
import ModalFluent from '../../tools/modalFluent/ModalFluent';
import { render } from 'react-dom';
import LenguajeContext from '../../../context/lenguaje/lenguajeContext';
import MenuHeader from '../../../components/navigation/menuHeader/menuHeader.component'
import {SERVISE_URI, SSO_URI} from '../../../assets/settings/settinsrouters';
import { HubConnectionBuilder } from '@microsoft/signalr';
//*****Methods*****//

/**
 *@author Jhon Sebastian Beltran(26-04-2022)
 *@version 1.0
 *@lastCommit Jhon Sebastian Beltran(27-07-2022)
 */

const Warn = (props) => {
    const nameClient = CookiesJS.get("nameClient");
    const dataClient = JSON.parse(CookiesJS.get("dataClient"));
    const { clientName } = useParams();
    const RequestVerification = new RequestFetch('IdentityVerification/linkChallengeInfo')
    const RequestVerificationResp = new RequestFetch('IdentityVerification/response')
    const [infoIdentity, setInfoIdentity] = useState({})
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);  
    const Requests = new RequestFetch(`access/client`);
    const requestLang = new RequestFetch(`Languages`)
    const requestDomain = new RequestFetch("access/text");
    const [controlsPage, setControlsPage] = useState({});
    const style = document.documentElement.style
    const [textOnPages, setTextOnPages] = useState({})
    const [idiom, setIdiom] = useState({})
    const [itemFiles, setItemFiles] = useState({})
    const [infUsr, setinfUsr] = useState({})
    const [controlsPageForH, setControlsPageForH] = useState({})
    const [connection, setConnection] = useState(null)
    const [valid, setValid] = useState(false)
    
    // if (condition) {
    style.setProperty('--background-image', '')
    // }
    const dataQ = window.location.search;
    const params = new URLSearchParams(dataQ)
    params.get('vc')
    const [idLng, setIdLng] = useState()

    const vc = params.get('vc')
    /************************
     * @arguments 
     ************************/
    // props.invalidGetLng(false)
     const indexedDB = window.indexedDB
     let db

     const requestInfo = indexedDB.open('textList',1)
     useEffect(() => {
         requestInfo.onsuccess =async()=>{
            //  alert('sdsdsd')
             db = requestInfo.result
             setTextOnPages(await consulta(db)) 
         }
     }, [])

    /************************
     * @methods 
     ************************/

    const onClickTyC = (data) => {

        if (data) {
            props.openPanel(data)
        }
    }
    const redirectHome = () =>{
        // window.location.href = `${SSO_URI}/${nameClient}/home`;
        let infor ={
            title: controlsPage.lblSuccessfulProcess?.languagewebcontrols[0].value,
            description: controlsPage.lblMessageVerificationLink?.languagewebcontrols[0].value,
            txtButton: controlsPage?.smtAccept?.languagewebcontrols[0].value,
            type: 'success'
          }
        render(<ModalFluent
            cancelIcon={true}
            disableClose={true}
            props={infor}
            actionOnClick={()=>{window.close();}}
            redirect={true}
            objectRequest={()=>{window.close();}}
            />,
            document.getElementById("modalxd"))
    }


    /************************
     * @hooks 
     ************************/

    useEffect(async () => {
        buildTableInf()
    }, [idLng])
    
    const buildTableInf = async() =>{
        const idConnectionLink = CookiesJS.get("idConnectionLink");
        if (idConnectionLink) {

        const url = window.location.href;
        const vcParam = url.split("vc=")[1]?.split("&")[0];
        const header = idLng?{idLanguageUser:idLng,idConnection:idConnectionLink, id:vcParam}:{idConnection:idConnectionLink, id:vcParam}
        const promise = await RequestVerification.getItem({
            tenant: nameClient,
            headerEx:header
        });
        if (promise.data.data) {
            const info = promise.data.data
            if (idLng) {
                setInfoIdentity(info)
                setinfUsr({
                    Domain:info.domain,
                    idLng:undefined,
                    tenant:nameClient,
                    usr:info.username
                })
                
            
            let controlsPage = {}
            let controlsPageForH = {}
            const response = await Requests.getById({id: '', tenant:nameClient });
            const promiseAccess = await requestDomain.getItem({
                param: {idLanguage: idLng}, 
                tenant: nameClient,
                headerEx:{idLanguageUser:idLng}
            });
    
            promiseAccess.data.data.map((spd) =>{
                if(spd.path == 'identity-verification' || spd.path == 'controls') {
    
                    spd.webcontrolspages.map((data)=>{
    
                        controlsPage[data.idHtml] = data;
                        controlsPageForH[data.idHtml] = data.languagewebcontrols[0].value;
                    })
    
                }
            })
            setControlsPageForH(controlsPageForH)
            setControlsPage(controlsPage)
            getLenguaje(response.data.ssoPagesData)
    
            const lang = await requestLang.getItem({
                id:'',
                tenant:nameClient,
                param: {idClient:dataClient.idClient},
                headerEx:{idLanguageUser:idLng}
            })
            setIdiom(lang.data.data)
    
            const fileResponse = {
                idLanguage:idLng,
                isToAdmin:false,
            };
            const idClient=response.data.data.idClient
            const requestFiles= new RequestFetch (`Access/file/${idClient}`);
            const dataResponse = await requestFiles.getItem({
                param: fileResponse,
                tenant: nameClient,
                ct:'json',
                reload:true,
                cancelIcon:true
            })
            setItemFiles(dataResponse.data.data)
        }else{
            setValid(true)
            setIdLng(info.idLanguage)
        }
    
    
        }else{
            const promiseAccess = await requestDomain.getItem({
                param: {idLanguage: CookiesJS.get('idLng')}, 
                tenant: nameClient,
                headerEx:{idLanguageUser:CookiesJS.get('idLng')}
            });
    
            promiseAccess.data.data.map((spd) =>{
                if(spd.path == 'identity-verification' || spd.path == 'controls') {
    
                    spd.webcontrolspages.map((data)=>{
    
                        controlsPage[data.idHtml] = data;
                        controlsPageForH[data.idHtml] = data.languagewebcontrols[0].value;
                    })
    
                }
            })
            setControlsPageForH(controlsPageForH)
            setControlsPage(controlsPage)
    
            const {code, detail, title} = promise.data.errors[0]
            let infor ={
                title: title,
                description: code + ' - ' + detail,
                type: 'danger',
                txtButton: controlsPage?.smtAccept?.languagewebcontrols[0].value,
              }
              render(<ModalFluent
                cancelIcon={true}
                props={infor}
                actionOnClick={()=>{window.close();redirectHome()}}
                redirect={true}
                objectRequest={()=>{window.close();redirectHome()}}
                />,
                document.getElementById("modal"))
        }
        }
        
    }

    const setConfirm = async (opt) => {
        const responseValue = {
            "isAccepted": opt
        }
        let paramsChallenges = new URLSearchParams({
            username: infoIdentity.username,
            domain: infoIdentity.domain,
            verification: infoIdentity.verification,
            challenge: infoIdentity.challenge,
            idRequest: infoIdentity.idRequest
        }).toString();


        RequestVerificationResp.createItemParams({
            param: paramsChallenges,
            tenant: nameClient,
            body: responseValue,
            option: ()=>{window.close();},
            validator: true,
            cancelIcon:true,
            disableClose:true,
            headerEx:{idLanguageUser:idLng},
            txtButton: controlsPage?.smtAccept?.languagewebcontrols[0].value,
        }).then(e => {
            if (e) {
                window.close()
                // window.location.href = `${SSO_URI}/${nameClient}/home`
                redirectHome()
            }
        })
    }

    const getConnectionId = () => {
        connection.invoke("Getconnectionid").then((data) => {
          
          CookiesJS.set("idConnectionLink", data);
          saveSocketConnection(data.data).then((response) => {
            console.log("save socket connection works", response);
          });
          
        });
      };
    
      const saveSocketConnection = (idConection) => {
        CookiesJS.set("idConnectionLink", idConection);
        return true;
      };
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${SERVISE_URI}identityVerificationHub/`)
            // .withAutomaticReconnect()
            .build();
            newConnection.onreconnected((connectionId) => {
                getConnectionId();
            });

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection
                        .invoke("Getconnectionid")
                        .then((data) => {
                        
                        CookiesJS.remove("idConnectionLink");
                            CookiesJS.set("idConnectionLink", data);
                            buildTableInf()
                        })
                        .catch((error) => {
                        console.log(error);
                        });
    
                    connection.on('ReceiveVerificationLink', (data) => {
                        console.log(data)
                        if (!data.data.name) {
                            }else{
                              let info ={
                                title: data.data.name,
                                description: data.data.code+ ' - ' +data.data.message,
                                type:'danger'
                            }
                            render(
                                <ModalFluent
                                    props={info}
                                    reload={true}
                                    cancelIcon={true}
                                    actionOnClick={()=>{window.close();redirectHome()}}
                                    redirect={true}
                                    objectRequest={()=>{window.close();redirectHome()}}
                                    // confirm={actionConfirm}
                                    // objectRequest={redirectPasword}
                                />,
                                document.getElementById("modal")
                            );
                            }
                    });

                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);


    /************************
     * @resturns 
     ************************/
    return (
        <>
        <div id="modal"></div>
        <div id="modalxd"></div>
        {Object.entries(infoIdentity).length>0 && Object.entries(controlsPage).length>0?
        <>
            <div className="container-resp">
                <div id='ratttt' className="container--general">
                    <div className="container-center">
                    <MenuHeader warn={controlsPageForH} lng={idiom} inf={infUsr} files={itemFiles} idLngW={(e)=>setIdLng(e)}/>
                    <div className="div-dark"></div>

                        <center><img src={imgTop} className="image-top" /></center>
                        <div className="icn-msg mg-a w7">
                        <FontIcon aria-label="Compass" id="linetext" iconName="Warning" className='icon-warn' /><p className='color-text'>{controlsPage.lblSuspiciousStartAttempt?.languagewebcontrols[0].value}</p>
                        </div>
                        <div className="cont-down">
                            <div className="warn-message">
                                <p className='pstyle' style={{marginTop:"15px"}}> {controlsPage.lblSomeoneJustTriedAccessYourAccount?.languagewebcontrols[0].value}</p>
                            </div>
                            <div style={{"overflow-x":'auto'}} className='pdg-lat' >
                                <table>
                                <tbody>
                                    <tr>
                                    <td><p className='txto txt-b' style={{marginTop:"15px"}}>{controlsPage.lblWhen?.languagewebcontrols[0].value}:</p></td>
                                    <td><p className='txto' style={{marginTop:"15px"}}>{infoIdentity.date}</p></td>


                                    </tr>
                                    <tr >
                                    <td ><p className='txto txt-b mg10-h' style={{marginTop:"2px"}}>{controlsPage.lblDevice?.languagewebcontrols[0].value}:</p></td>
                                        <td className='w5'>
                                            <p className='txto'style={{marginTop:"15px"}}>
                                                {`${infoIdentity.browserData.deviceType} `}
                                                {`${infoIdentity.browserData.deviceName} `}
                                                {`${infoIdentity.browserData.operatingSystem} `}
                                                {`${infoIdentity.browserData.browserVersion}`}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                    {/* <td><p className='txto txt-b'>{controlsPage.lblIPAdress?.languagewebcontrols[0].label}</p></td>
                                    <td className='w6'><p className='txto'>{infoIdentity.publicIp}</p></td> */}
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <div className="div-button">
                                <center>
                                    <button className='button--success button--default c--flex-wsb padd-button'  onClick={() => setConfirm(true)}>{controlsPage.smtAllow?.languagewebcontrols[0].label}</button>
                                    <button className='button--danger button--default c--flex-wsb padd-button' onClick={() => setConfirm(false)}>{controlsPage.smtDeny?.languagewebcontrols[0].label}</button>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                    <footer style={{marginTop: '5vh'}}>
                        <div className="admin--container-footer">
                            <div>
                                <h6 className="hipertext hipertext--second" >
                                    <label><a className="hipertext hipertext--second" onClick={() => onClickTyC(true)}>{controlsPage.urlTermsCondition?.languagewebcontrols[0].value}.</a></label>
                                </h6>
                            </div>
                            <div>
                                <h6>BDM&#169; 2023 - {controlsPage.lblAllRightsReserved?.languagewebcontrols[0].value}</h6>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
            :
            <ContainerBaseLoading />
        }
        </>
    );

}

export default Warn;