//****Librarys ****//
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Checkbox, FontIcon, ZIndexes,/*, Stack*/ } from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {render} from "react-dom";
import CookieJS from 'js-cookie';

//*****Componnents*****//
import ButtonFloat from '../components/tools/buttonFloat/buttonFloat.component';
import ContainerCaptcha from '../components/container/contianerCaptcha/containerCaptcha.component';
import ContainerBaseLoading from '../components/container/containerLoading/containerBaseLoading.component';
import InputFile from '../components/controls/inputFile/inputFile.component';

//*****Methods*****//
import {EncodeB4/*, DecodeB4*/} from '../utils/encode/encode';
import { AuthConnect} from '../utils/auth/oauth.Controllers';
 
//*****Context*****//
import AuthContext from '../context/auth/authContext';

//*****Setting*****//
import {BSR_URI,PATH_APPLICATION,PATH_SSO,SSO_URI} from '../assets/settings/settinsrouters';
import { ConvertForm } from '../utils/validation/validateForm';

//*****helpers*****//
import { getLanguages } from '../helpers/languages/getLanguages';
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
import { consulta, RequestFetch } from '../utils/Requests/requests.Controller';
import LenguajeContext from '../context/lenguaje/lenguajeContext';
/************************
*@arguments
*****************/

 /*****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const LoginPassword = (props) =>{
    /************************
     * @arguments 
     ************************/
        const {state, getError} = useContext(AuthContext, )
        const {getLenguaje, stateLanguage} = useContext(LenguajeContext);   
        const [acceptedTerms, setAcceptedTerms] = useState(state.acceptedTerms)
        const [defaultAcceptedTerms, setDefaultAcceptedTerms] = useState(state.acceptedTerms)
        const [captchaData, setCaptchaData] = useState({dataCaptcha :state.dataCaptcha, 
        idLanguage :state.idLanguage})
        const [renderCaptchaData, setRenderCaptchaData] = useState()
        const navigate = useNavigate()
        const [userPages, setUserPages] = useState([])
        const [resetInp, setResetInp] = useState()
        const [stateLoading, setStateLoading] = useState(null)
        const [dataError, setDataError] = useState(null)
        const { clientName } = useParams();
        const [role, setRole] = useState({
            '0': 'user',
            '1': 'administration'
        })
        const nameClient = CookieJS.get('nameClient');
        const firstRender = useRef(true);
        const [dataUserVal, setDataUserVal] = useState(CookieJS.get('dataUser'));
        const [lngVal, setLngVal] = useState(null);
        const [dataTokenVal, setDataTokenVal] = useState(null);
        const [dataTokenVal2, setDataTokenVal2] = useState(null);
        const [tokenVal, setTokenVal] = useState(null);
        const [imageBase64, setImageBase64] = useState(null);
        const [errorMesage, setErrorMesage] = useState(false)
        const [messageError, setMessageError] = useState(false)
        const [valPass, setValPass] = useState('')     
        const [textOnPages, setTextOnPages] = useState({})
        const [isLoad, setIsLoad] = useState(false)   
        const script = document.createElement("script")
        const childRef = useRef();
        const recaptchaRef = useRef();
        const [idiom, setIdiom] = useState(props.idiom)


        // Variables implementacion de textos -----------------------------------------------------------------------------------------
        
        const indexedDB = window.indexedDB
        let db

        const showCapt = ()=>{
            const demoClasses = document.querySelectorAll('.grecaptcha-badge');
            demoClasses.forEach(element => {
                element.style.display=''
            });
        }

        const requestInfo = indexedDB.open('textList',1)
        useEffect(() => {
            showCapt()
            requestInfo.onsuccess =async()=>{
                // alert('sdsdsd')
                db = requestInfo.result
                setTextOnPages(await consulta(db)) 
            }
        }, [])
        
        const Requests = new RequestFetch(`Access/client`);
        const [controlsPage, setControlsPage] = useState({});

        // ----------------------------------------------------------------------------------------------------------------------------
    /************************
     * @methods 
     ************************/     

    const setCaptchaLang = (recaptchaContainer, lang) => {

        if (recaptchaContainer != null) {
          const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');
          const currentLang = iframeGoogleCaptcha?.getAttribute("src").match(/hl=(.*?)&/).pop();
    
          if (currentLang !== lang && iframeGoogleCaptcha != null) {
              iframeGoogleCaptcha.setAttribute(
                  "src",
                  iframeGoogleCaptcha.getAttribute("src").replace(
                      /hl=(.*?)&/,
                      'hl=' + lang + '&'
                  )
              );
          }
        }
    }

    const resetCaptcha = () => {
        if (props.safeKey == 1) {
            childRef.current.props.onClick();
        }
    };
        
     const actionOnClick=(e)=>{
       return e;
     }     
     const onClickTyC=(data)=>{
        if (data) {
            props.openPanel(data)
        }
     }
     const actionConfirm=()=>{
        setDataError(false)
     }
    const Methods = {
        validateUser: async ()=>{
            
            if(state.user === '' || state === ''){
                navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
            }else{
                const response = await AuthConnect({
                    type: 'user',
                    user: state.user, 
                    roll: state.roll,
                    tenant: nameClient
                })
                if(response.status != 200){
                    
                    response.errors.map(error=>{
                        let info ={
                            title: error.title,
                            description: error.code + ' - ' + error.detail,
                            type:'danger'
                        }
                        setDataError(info)
                        setStateLoading(response)
                        render(
                            <ModalFluent
                                props={dataError}
                                actionOnClick={actionOnClick}
                                confirm={actionConfirm}
                            />,
                            document.getElementById("modal")
                        );
                    })
                    // getError(response.errors[0])
                    // navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginForm`);   
                }
                else if(response){
                    setCaptchaData(
                        {dataCaptcha :state.captchadata, 
                            idLanguage :state.idLanguage}
                    )
                    setAcceptedTerms(response.data.data.acceptedTerms)
                    setDefaultAcceptedTerms(response.data.data.acceptedTerms)
                }
                const stringToJson = JSON.stringify(response.data.data);
                if(response.errors){
                    
                    // getError(response.errors[0])
                    // navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginForm`);   
                }
                if(response.data.data.isLocked === true ){
                    navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/IsLocked`);
                }
                else{
                    
                    setStateLoading(response.data.data.userExist)
                    CookieJS.set('dataUser', stringToJson)
                    setDataUserVal(stringToJson);
                    //setStateLoading(respose.data.data.user.active)
                    
                }
            }
        },
        validatePass: async(e)=>{
            e.preventDefault()
            setIsLoad(true)
            let responseTok = ''
            if (props.safeKey) {
                switch (props.safeKey) {

                    case 2:
                        responseTok=window.grecaptcha.getResponse()
                        break;

                    case 3:
                        const tokenG = await new Promise((resolve) => {
                            window.grecaptcha.ready(() => {
                              window.grecaptcha.execute(props.siteKey, { action: 'submit' }).then((token) => {
                                resolve(token);
                              });
                            });
                          });
                        
                          responseTok = tokenG;
                        break;
                
                    default:
                        break;
                }
                
            }
            const dataForm = ConvertForm(e)
            const credential = EncodeB4({value: state.user, value2:dataForm.pass})
           
            const token = await AuthConnect({
                type: 'token',
                credential: credential, 
                roll: state.roll,
                tenant: nameClient,
                acceptedTyC: acceptedTerms,
                ...props.safeKey==1?{captchaImage: imageBase64}:null,
                leng:props.idLng,
                responseToken:responseTok
            })
           
        
            if(token.captchadata && Object.entries(token.captchadata).length > 0){
                let datCapt = token.captchadata
                let imgCaptcha={
                    dataCaptcha:{
                        idCaptcha: datCapt.idCaptcha,
                        imagesBase64:datCapt.imagesBase64,
                        nameImage:datCapt.nameImage
                    }
                    
                    
                }
                setCaptchaData(imgCaptcha)
            }
            if(token.data){
                let dataU = JSON.parse(CookieJS.get('dataUser'));
                dataU = Object.assign(dataU,{
                    client: token.data.data.client,
                    showAdmin: token.data.data.showAdmin,
                    urlCallback: token.data.data.urlCallback,
                    user: token.data.data.user
                })
                let tempPages = []
                token.data.data.userPages.map((pages) => {
                    tempPages.push(pages.path)
                    setUserPages(tempPages)

                })
                if(tempPages.length == token.data.data.userPages.length){
                    CookieJS.set('pages', JSON.stringify(tempPages), { secure: true, autoencode: true })
                }

                
                const dataT = Object.assign({
                    //...token.data.data,
                    idDomain: token.data.data.user.idDomain,
                    indicator: token.data.data.indicator,
                    colorLevel: token.data.data.token.colorLevel,
                    dateLastLogin: token.data.data.token.dateLastLogin,
                    expirationDays: token.data.data.token.expirationDays,
                    expires_in: token.data.data.token.expires_in,
                    firstName: token.data.data.token.firstName,
                    lastName: token.data.data.token.lastName,
                    nameFormatted: token.data.data.token.nameFormatted,
                    passwordLastSet: token.data.data.token.passwordLastSet,
                    token_type: token.data.data.token.token_type,
                    userPasswordExpiryTime: token.data.data.token.userPasswordExpiryTime
                })
                CookieJS.set('dataUser', JSON.stringify(dataU));
                setDataUserVal(dataU)
                CookieJS.set('dataToken', JSON.stringify(dataT));
                setDataTokenVal(dataT)
                CookieJS.set('token', token.data.data.token.access_token);
                CookieJS.set('refresh_token', token.data.data.token.refresh_token, { secure: true });
                setTokenVal(token.data.data.token.access_token)
                const dataToken = token.data.data.token.access_token;
                getLanguages(dataToken, clientName)
                    .then(lng =>{
                        const jsonLng = JSON.stringify(lng);
                        CookieJS.set('languages', jsonLng);
                        setLngVal(jsonLng);
                    })
            }else{
                if (recaptchaRef.current) {
                    window.grecaptcha.reset(recaptchaRef.current);
                }
                    
                if (dataForm.pass.length === 0){
                    setErrorMesage(true)
                    setIsLoad(false)
                    let info ={
                                title: token.errors[0].title,
                                description:token.errors[0].code+": "+token.errors[0].detail,
                                type:'danger',
                             }
                            render(
                                <ModalFluent
                                    props={info}
                                    actionOnClick={actionOnClick}
                                    confirm={actionConfirm}
                                />,
                                document.getElementById("modal")
                            );
                }
                else {
                    
                    setIsLoad(false)
                    token.errors.map((error)=>{
                        let info ={
                            title:error.title,
                          description:error.code+": "+error.detail,
                            type:'danger',
                            action:()=>{limpForm();resetCaptcha()}
                         }
                        let validator = false;
                        let reload = false;
                        let cancelIcon=false;
                         if (error.code == 'FI-0114') {
                            validator = true;
                         }
                         if (error.code == 'FI-0115') {
                            reload = true;
                         }
                         if(error.code == 'FI-0117'){
                            cancelIcon = true;
                            validator = true;
                         }
                         if (error.code == 'FI-0111'){
                            reload = true;
                        }
                        render(
                            <ModalFluent
                                props={info}
                                actionOnClick={(e)=>{redirectResetPassword(e)}}
                                confirm={actionConfirm}
                                objectRequest ={redirectResetPassword}
                                 redirect={validator}
                                 reload={reload}
                                 cancelIcon={cancelIcon}
                                //  validator={true}
                            />,
                            document.getElementById("modal")
                        );
                     })
                }    
            }
        

        }
    }
    const limpForm = ()=>{
        setResetInp(true)
        document.inputfprpass.reset()
    }
    const redirectResetPassword = () => {
        navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/ResetPassword`);
    }

    /************************
     * @hooks 
     ************************/

     useEffect(() => {
        props.textBsr(
            <ButtonFloat 
                icon={<FontIcon aria-label="ChevronLeftSmall" iconName="ChevronLeftSmall" className="hipertext--icon" />}
                onClick={()=>navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/LoginForm`)}
                type={1} 
                typef="btnFloat-header" 
                text= {state.user}
                special={true}
            />
        )
        if(state.user === '' || state === ''){
            navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
        }
        // const response = Requests.getById({id:'', tenant: clientName });
        // response.then(data=>{

        let controlsPage = {};
        if (stateLanguage.lenguaje !=0) {
            stateLanguage.lenguaje.map((pass) => {
                if (pass.path == 'login-password' || pass.path == 'controls') {

                    pass.webcontrolspages.map((data) => {
                        
                            controlsPage[data.idHtml] = data;
                        
                        
                    })
                    setControlsPage(controlsPage)  
                }               
                if (pass.langCaptcha) {
                    setIdiom(pass.langCaptcha)
                    setCaptchaLang(document.getElementById("recaptcha-container"),pass.langCaptcha)
                }
            })           
        }else{
            if (Object.entries(textOnPages).length > 0) {
                textOnPages.ssoPagesData.map((users) => {
                    if (users.path == 'login-password' || users.path == 'controls') {
                        users.webcontrolspages.map((data) => {
                            controlsPage[data.idHtml] = data;
                        })
                    }
                    setControlsPage(controlsPage)
                    if (users.langCaptcha) {
                        setIdiom(document.getElementById("recaptcha-container"),users.langCaptcha)
                    }
                })
            }
        }

        // })
    }, [textOnPages, stateLanguage])

    useEffect(()=>{
        setDataTokenVal2(CookieJS.get('dataToken'));
    }, [dataTokenVal])

     useEffect(() => {
        if((lngVal !== null) && (dataTokenVal2 !== null || dataTokenVal2 !== undefined) && (tokenVal !== null)){
            // if(dataUserVal.showAdmin === true){
            //     window.location.href = `${BSR_URI}${PATH_APPLICATION==''?PATH_APPLICATION:PATH_APPLICATION+'/'}${clientName}/${role[1]}`
            // }
            // else{
            //     window.location.href = `${BSR_URI}${PATH_APPLICATION==''?PATH_APPLICATION:PATH_APPLICATION+'/'}${clientName}/${role[0]}`
            // }
            window.location.href = dataUserVal.urlCallback
        }
     }, [lngVal, dataTokenVal, tokenVal])

     
    useEffect(()=>{
        switch (props.safeKey) {
            case 1:
                setRenderCaptchaData(
                    <div className='c--fit-cont mg-a'>
                        <ContainerCaptcha 
                            ref={childRef}
                            dataCaptcha ={captchaData}  
                            actionOnChange={actionOnChange} 
                            idLanguage={JSON.parse(CookieJS.get('dataUser')).idLanguage}
                            order={controlsPage.lblSelectTheCorrectCaptchaImage?.languagewebcontrols[0].value}
                        />
                    </div>)
                break;
                
                case 2:
                    script.src = `https://www.google.com/recaptcha/api.js?hl=${idiom}`
                    if (firstRender.current) {
                        // En la primera renderización, no hacemos nada
                        firstRender.current = false;
                        return;
                    }else{
                    
                    setCaptchaLang(document.getElementById("recaptcha-container"), idiom)
                    // script.src = `https://www.google.com/recaptcha/api.js?hl=${idiom}`
                      setRenderCaptchaData(
                        <div id="recaptcha-container" className="w10 d--flex c--flex-wc mg20-h" style={{paddingTop:'30px'}}>
                            <div ref={recaptchaRef} className="g-recaptcha" data-sitekey={props.siteKey}></div>
                        </div>
                    )
                      }
                break;
                    
            case 3:
                
            script.src = `https://www.google.com/recaptcha/api.js?render=${props.siteKey}`
                setRenderCaptchaData(
                    <div className="w10 d--flex c--flex-wc mg20-h">
                    <div 
                        ref={recaptchaRef}
                        className="g-recaptcha" 
                        data-sitekey={props.siteKey}
                        data-size="invisible"
                    ></div>
                    </div>
                )
                    
            default:
                break;
        }
        
        document.body.appendChild(script)
    }, [captchaData,controlsPage,idiom])

    const actionOnChange = (data) =>{
        setDataError()

        setImageBase64(data);
    }
    const changeCheckbox = (data) =>{
        if(!defaultAcceptedTerms){
            setAcceptedTerms(data.target.checked)
        }
    }
    /************************
     * @resturns 
     ************************/
        // if(!stateLoading){
        //     return(<ContainerBaseLoading />)
        // }
        if (Object.entries(controlsPage).length > 0) {

            return(
                <>
                <div id="modal"></div>
                    {/* {dataError?
                        <ModalFluent
                            props={dataError}
                            actionOnClick={actionOnClick}
                            confirm={actionConfirm}
                        />
                    :''} */}
                    {isLoad?
    
                        <ContainerBaseLoading />
                        :''
                    }
                    
                    <div className="mg-tp15">
                    <label>
                        {controlsPage.txtPassword?.languagewebcontrols[0].placeholder}
                    </label>
                    </div>
                    <form 
                        onSubmit={Methods.validatePass} 
                        name="inputfprpass"
                        className="container--flex c--flex-hi c--flex-dc c--flex-f1 w10"
                    >
                        <InputFile 
                            name="pass" 
                            type="password" 
                            placeholder= {controlsPage.txtPassword?.languagewebcontrols[0].label}
                            onChange={(e)=>{setResetInp(false);setErrorMesage(false);setMessageError(false);setDataError(false)}}
                            maxLength={controlsPage.txtPassword.maxlength}
                            minLength={controlsPage.txtPassword.minlength}
                            onMessage={(e)=>{setMessageError(e)}}
                            reset={resetInp}
                            isQuestion={true}
                        />
                        {errorMesage?
                        <div className='message-error'>
                            <p className='paragraf-error'>{controlsPage.txtPassword?.languagewebcontrols[0].errorRequiredMessage}</p>
                        </div>
                        :''}
                        {messageError ? 
                        <div className='message-error'>  
                            <p className='paragraf-error'>{controlsPage.txtPassword?.languagewebcontrols[0].infoMessage}</p>
                        </div>
                        :''}                        
                        <a 
                            onClick={() => redirectResetPassword()}
                            className="hipertext hipertext--primary-lft"
                        >{controlsPage.OnClickForgotYourPassword?.languagewebcontrols[0].value}</a>
                        {renderCaptchaData ? renderCaptchaData:""}
                        {acceptedTerms?
                            <Checkbox   
                                checked={acceptedTerms}
                                label= {     
                                    <label> {controlsPage.lblThisProcessRequiresTheAcceptanceOf?.languagewebcontrols[0].value} <a className="hyperText-TyC" onClick={()=>onClickTyC(true)}>{' '+controlsPage.chkTermsCondition?.languagewebcontrols[0].label}</a></label>
                                }
                                onChange={changeCheckbox}
                                // disabled   
                            />
                       :
                            <Checkbox
                                defaultChecked={false}
                                label= {     
                                    <label> {controlsPage.lblThisProcessRequiresTheAcceptanceOf?.languagewebcontrols[0].value} <a className="hyperText-TyC" onClick={()=>onClickTyC(true)}>{' '+controlsPage.chkTermsCondition?.languagewebcontrols[0].label}</a></label>
                                }
                                onChange={changeCheckbox}
                            />
                        } 
                        {acceptedTerms?
                            <section className="c--flex-f1 container--flex c--flex-wc c--flex-he">
                                <button type='submit' className="button--default button--primary" >
                                {controlsPage.smtNext?.languagewebcontrols[0].label}
                                </button> 
                            </section>
                        :
                            <section className="c--flex-f1 container--flex c--flex-wc c--flex-he">
                                <button type="submit" className="button--default button--disabled" disabled>
                                {controlsPage.smtNext?.languagewebcontrols[0].label}
                                </button> 
                            </section>
                        }
                    </form>
                </>
            );
        }else {
            return(
                <ContainerBaseLoading/>
            );
        }
        
}

export default LoginPassword;