import React from 'react';

const ButtonBlock = (props)=>{

    /************************
     * @arguments 
     ************************/

    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/

    /************************
     * @resturns 
     ************************/
        return(
            <div className="container--buttonBlock" onClick={props.onClick}>
                <div className="container--buttonBlock-icon">
                    <img src={props.icon} alt=""/>
                </div>
                <div className="container--buttonBlock-text">
                    <p>{props.text}</p>
                </div>
            </div>
        );
}

export default ButtonBlock;