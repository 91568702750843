export const ValidatePass = () =>{
    const expr = /[a-zA-Z0-9]*@$/

    const validate = expr.test()
}

export const ValidateInupts =()=>{

}

export const ConvertForm = (e) =>{
    const formData = new FormData(e.target)
    const data = {}

    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    }
    return data
}