export default (state, action)=>{
    const {payload, type} = action

    const TYPES_GETS = {
        'GET_AUTH_USER':{
            ...state,
            user: payload.user,
            roll: payload.roll,
            dataCaptcha: payload.dataCaptcha,
            idLanguage: payload.idLanguage,
            acceptedTerms: payload.acceptedTerms
        },
        'GET_AUTH_ERROR': {
            ...state,
            error: payload
        }
    }
    
    return TYPES_GETS[type]
}