//****Librarys ****//
import React, { useContext, useState, useEffect } from "react";
import { Checkbox, Stack } from "@fluentui/react";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import CookieJS from "js-cookie";
import { HubConnectionBuilder } from "@microsoft/signalr";

//*****Componnents*****//
import ButtonFloat from "../components/tools/buttonFloat/buttonFloat.component";
import ContainerCaptcha from "../components/container/contianerCaptcha/containerCaptcha.component";
import { useNavigate, useParams } from "react-router-dom";

//*****Context*****//
import AuthContext from "../context/auth/authContext";

//*****Methods*****//
import { RequestFetch,consulta } from "../utils/Requests/requests.Controller";
import InputFile from "../components/controls/inputFile/inputFile.component";
import { PATH_SSO, SERVISE_URI, SSO_URI } from "../assets/settings/settinsrouters";
import { ConvertForm } from "../utils/validation/validateForm";
import ModalFluent from "../components/tools/modalFluent/ModalFluent";
import { render } from "react-dom";
import RecoverPassword from "./RecoverPassword";
import LenguajeContext from '../context/lenguaje/lenguajeContext';
import ButtonBlock from "../components/tools/buttonBlock/buttonBlock.component";
import ButtonForm from "../components/tools/buttonForm/buttonForm";

/*****************
 *****************
 * @returns copomnent BDM-SSO
 *****************
 *****************/

const ResetPassword = (props) => {
  /************************
   * @arguments
   ************************/
  const [questions, setQuestions] = useState();
  const navigate = useNavigate();
  const { state, getError } = useContext(AuthContext);
  const [resultResponse, setResultResponse] = useState(false);
  const { clientName } = useParams();
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const [isPass, setIsPass] = useState(true);
  let idConnection = CookieJS.get("idConnection");
  const requests = new RequestFetch(`IdentityVerification/post`);
  const strChallenge = new RequestFetch(`IdentityVerification/challenges`);
  const response = new RequestFetch(`IdentityVerification/response`);
  const enableCount = new RequestFetch(`Access/enableAccount/${state.roll}/${state.user}`);
  const enableMfa = new RequestFetch(`Access/changeMFAStatus/${state.user}/${state.roll}`);
  const [dataPost, setDataPost] = useState([]);
  const [position, setPosition] = useState(0);
  const [currentChallenge, setCurrentChallenge] = useState();
  const [challengesID, setChallengesID] = useState();
  const [requiredChallenge, setRequiredChallenge] = useState([]);
  const [optionalChallenge, setOptionalChallenge] = useState([]);
  const [connection, setConnection] = useState(null);
  const [enableEmail, setEnableEmail] = useState(Boolean)
  const itemsChoice = [
    { key: 0, text: "Enviar código al correo alterno." },
    { key: 1, text: "Responder preguntas y establecer contraseña nueva." },
  ];
  const [optSwitch, setoptSwitch] = useState(props.rendOpt)
  const [idConectCok, setPrueba] = useState('')
  const [inputs, setInputs] = useState({});
  const params = new URLSearchParams({
    username: state.user,
    domain: state.roll,
    idRequest:idConnection
  }).toString();
  const [paramsChallenges, setParamsChallenges] = useState();
  const [textOnPages, setTextOnPages] = useState({})
  const [confirmEmail, setConfirmEmail] = useState(null)
  const [challengeSelected, setChallengeSelected] = useState(0);

  // Variables implementacion de textos -----------------------------------------------------------------------------------------
        
  const indexedDB = window.indexedDB
  let db

  const requestInfo = indexedDB.open('textList',1)
  const hideCapt = ()=>{
    const demoClasses = document.querySelectorAll('.grecaptcha-badge');
    demoClasses.forEach(element => {
        element.style.display='none'
    });
  }
 
  useEffect(() => {
      requestInfo.onsuccess =async()=>{
          // alert('sdsdsd')
          db = requestInfo.result
          setTextOnPages(await consulta(db)) 
      }
      hideCapt()
  }, [])

  const [controlsPage, setControlsPage] = useState({});

  // ----------------------------------------------------------------------------------------------------------------------------

  /************************
   * @methods
   ************************/
   useEffect(() => {
    props.textBsr(false)
    if(state.user === '' || state === ''){
        navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
    }
    // const response = Requests.getById({id:'', tenant: clientName });
    // response.then(data=>{
    let controlsPage = {};
    if (stateLanguage.lenguaje !=0) {
      stateLanguage.lenguaje.map((pass) => {
            if (pass.path == 'verification-challenge' || pass.path == 'enable-account' || pass.path == 'controls') {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = {...data.languagewebcontrols[0],...{maxlength:data.maxlength,minlength:data.minlength}};
              })
              setControlsPage(controlsPage)
            }
                         
        })           
    }else{
      if (Object.entries(textOnPages).length > 0) {
          textOnPages.ssoPagesData.map((pass) => {
            if (pass.path == 'verification-challenge' || pass.path == 'enable-account' || pass.path == 'controls') {
              pass.webcontrolspages.map((data) => {
                controlsPage[data.idHtml] = {...data.languagewebcontrols[0],...{maxlength:data.maxlength,minlength:data.minlength}};
              })
              setControlsPage(controlsPage)
            }
          })
      }
    }
    // })
  }, [textOnPages, stateLanguage])

  const enableMail = async (connectionId) => {
        // debugger
    // console.log(userSt);
    if(!connectionId){
      connectionId = CookieJS.get('idConnection');
    }
    if (state.user && state.roll) {
      const paramInitial = new URLSearchParams({
        username: state.user,
        domain: state.roll,
        idRequest:connectionId
      }).toString();
      const promisePost = await requests.createItemParams({
        param: paramInitial,
        tenant: clientName,
        headerEx:{idLanguageUser:props.idLng},
        reload:true,
        cancelIcon:true
      })
      setChallengesID(promisePost.data.data.id);
      setDataPost(promisePost.data.data.challenges);
    }
  };
  
  useEffect(async () => {
    // debugger
    if (state.user && state.roll) {
      if (props.isDisable == false) {
        setEnableEmail(props.isDisable)
        setConfirmEmail(props.isDisable)
      } else {
        const datP = [{idChallenge:0}]
      const challenge = await buildChallenge(0, datP, idConectCok);
      const conf = [challenge]
      setRequiredChallenge(conf);
      }
      
    }
  }, [controlsPage]);

  const handleChange = (value,name) => { 

     // debugger
     setInputs(values => ({...values, [name+""]: value}));
}
  const methodsChallenge = {
    // getData: () => {},
    // validateUser: () => {
    //   window.location = "/";
    // },
    responseChallenge: async (form) => {
      
      form.preventDefault();

    const formData = new FormData(form.target)
    const data = {}
        const responseValue = {
          value: form.target[0].value,
        };
        const paramsResponse = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
        }).toString();
        
        const dataResponse = await response.createItemParams({
          param: paramsResponse,
          tenant: clientName,
          headerEx:{idLanguageUser:props.idLng},
          body: responseValue,
          reload:true,
          cancelIcon:true
        })
        
        setChallengesID(dataResponse.data.data.id);
        setDataPost(dataResponse.data.data.challenges);
    },
    prueba:async(form)=>{
        form.preventDefault();
        // if(form.target.length > 0){
          const formData = new FormData(form.target);
          const data = Object.fromEntries(formData.entries());
          const payload = {
            securityQuestions : Object.keys(data)
                .map(key => ({
                  id: key,
                  reply: data[key]
                }))
          }
          setParamsChallenges(payload);
        // }

        const paramsResponse = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
        }).toString();
        const dataResponse = await response.createItemParams({
          param: paramsResponse,
          tenant: clientName,
          body: payload,
          headerEx:{idLanguageUser:props.idLng},
          reload:true,
          cancelIcon:true
        });
        setChallengesID(dataResponse.data.data.id);
        setDataPost(dataResponse.data.data.challenges);
    },
    
    changePass: async (form) => {
      form.preventDefault();
      const responseValue = {
        value: form.target[0].value,
      };
    },

    isEmailLegal: async (form) => {
      form.preventDefault();
      const inform = document.getElementById('myForm');
      const selectedOption = inform.querySelector('input[name="optboolean"]:checked'); 
        if (selectedOption.value=='true') {
          setEnableEmail(true)
          setConfirmEmail(true)
          enableMail(idConnection)
        }else{
          setEnableEmail(false)
          setConfirmEmail(false)
          enableMail(idConnection)
        }

      
    },
    habilitar: async(form) => {
      form.preventDefault();
      const paramsResponse = new URLSearchParams({
        verification: challengesID
      }).toString();

      const resp = await enableCount.createItemParams({
        param: paramsResponse,
        tenant: clientName,
        headerEx:{idLanguageUser:props.idLng},
        cancelIcon:true
      });
      let info ={
        title: controlsPage.lblSuccessfulValidation?.value,
        description: controlsPage.lblNotificationHasBeenSentYouremail?.value,
        txtButton: controlsPage?.smtAccept?.value,
        type:'success'
    }
      render(
          <ModalFluent
              props={info}
              reload={true}
              // confirm={actionConfirm}
              // objectRequest={redirectPasword}
          />,
          document.getElementById("modal")
      );
      

    },

    habilitarMfa: async(form) => {
      form.preventDefault();
      const paramsResponse = new URLSearchParams({
        username: state.user,
        domain: state.roll
      }).toString();
      let info ={
        title: controlsPage.lblSuccessfulProcess?.value,
        description: controlsPage.lblMFStatusUpdatedSuccessfully?.value,
        type:'success'
      }
      const resp = await enableMfa.createItem({
        ct:'json',
        tenant: clientName,
        headerEx:{
          idLanguageUser:props.idLng,
          verification:challengesID
        },
        cancelIcon:true,
        option:()=>{window.close();rediref()},
        infoModal:info,
        validator:true
      });
    }
  };

  
  const getConnectionId = () => {
    connection.invoke("Getconnectionid").then((data) => {
      
      CookieJS.set("idConnection", data);
      saveSocketConnection(data.data).then((response) => {
        console.log("save socket connection works", response);
      });
    });
  };

  const saveSocketConnection = (idConection) => {
    CookieJS.set("idConnection", idConection);
    return true;
  };

  const rediref = ()=>{
    window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`
  }

  /************************
   * @hooks
   ************************/

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${SERVISE_URI}identityVerificationHub/`)
      //.withAutomaticReconnect()
      .build();
    //getConnectionId()
    newConnection.onreconnected((connectionId) => {
      getConnectionId();
    });

    setConnection(newConnection);
  }, []);
  useEffect(() => {

    if (connection) {
      connection
        .start()
        .then((result) => {

          // setTimeout(() => {
          //     getConnectionId()
          // }, 2000);
          connection
            .invoke("Getconnectionid")
            .then((data) => {
             
              CookieJS.remove("idConnection");
                CookieJS.set("idConnection", data);
                if (props.isDisable == false) {
                enableMail(data)
                }
                setPrueba(data)
              

              // saveSocketConnection(data.data).then(response => {
              // console.log('save socket connection works', response);
              // });
            })
            .catch((error) => {
              console.log(error);
            });
          connection.on("ReceiveVerification", (data) => {
            if (!data.data.name) {
            setChallengesID(data.data.id);
            setDataPost(data.data.challenges);
            }else{
              let info ={
                title: data.data.name,
                description: data.data.code+ ' - ' +data.data.message,
                txtButton: controlsPage?.smtAccept?.value,
                type:'danger'
            }
            render(
                <ModalFluent
                    props={info}
                    reload={true}
                    // confirm={actionConfirm}
                    // objectRequest={redirectPasword}
                />,
                document.getElementById("modal")
            );
            }
            
          });


        })
        .catch((e) => {console.log("Connection failed: ", e)
      });
    }
  }, [connection]);

  if (connection) {
    connection.on("ReceiveVerification", (data) => {
      // console.log('ReceiveVerification', data.result);
    });
  }

  

  useEffect(async () => {
    if (dataPost.length == 1) {
      //si es igual a 1 es un desafio requerido
      let rc = [];
      if (idConectCok!='') {
        const challenge = await buildChallenge(0, dataPost, idConectCok);
      rc.push(challenge);
      setRequiredChallenge(rc);
      }
      
    } else if (dataPost.length > 1) {
      //Si es mayor a 1 el usuario debe elegir el desafio
      // debugger;
      let rc = [];
      let data = [{idChallenge: 4}]
      if (idConectCok!='') {
      const challenge = await buildChallenge(0, data, idConectCok);//primer parametro, posicion del desafio, id conectio es el identificador de la conexión,
      rc.push(challenge);
      setRequiredChallenge(rc);
      }
    } else {
      
      //Finalizó exitosamente los desafios
      if (challengesID) {
        switch (optSwitch) {
          case 1:
            setRequiredChallenge([{challengeComponent:
              <>
              <br />
              <div className="w10" style={{textAlign:'left'}}>
              <h3 className="mg10-h clr--dark-II">{controlsPage.lblUpdateOffice365MFAstatus?.value}</h3>
              </div>
              <form id="form1" onSubmit={methodsChallenge.habilitarMfa}>
                <label className="mg10-h">
                  {controlsPage.lblToUpdateUserOffice365MFAstatus?.value}
                </label>
                
                <center>
                {/* <button type="submit" className="button--default button--primary mrg-button">
                {controlsPage.smtlUpdateMFA?.languagewebcontrols[0].value}
                  </button> */}
                  <ButtonForm
                    text={controlsPage.smtlUpdateMFA?.value}
                    oneClick={true}
                    className="mg10-h"
                    classNameDiv="mg20-h"
                    formId="form1"
                  />
                  </center>
              </form>
            </>
            }])
            break;
        
          default:
              setRequiredChallenge([{challengeComponent:
                <>
                <RecoverPassword idLng={props.idLng?props.idLng:CookieJS.get('idLng')} states={state} idChallenger={challengesID} mConfirmation={controlsPage.lblYourRequestHasBeenSuccessful?.value} />
                
              </>,id:challengesID
              }])
            break;
        }
        
      }
      
      // return (
        
      // );
    }

    // if(dataPost.length > 0){
    //     let rc = [];
    //     let oc = [];
    //     for(let i = 0; i < dataPost.length; i++){
    //         if (dataPost[i].required === true) {
    //             rc.push(buildChallenge(i, dataPost));
    //         }else{
    //             oc.push(dataPost[i]);
    //         }
    //     }
    //     setRequiredChallenge(rc);
    //     setOptionalChallenge(oc);
    // }
  }, [dataPost,idConectCok,controlsPage]);

  const buildChallenge = async (position, dataPost, idConectCok) => {
    return {
      ...dataPost[position],
      isDone: false,
      challengeComponent: await challengesList(
        position,
        dataPost[position].idChallenge,
        idConectCok
      ),
    };
  };

  const challengesList = async (position, idChallenge, idConect) => {
    let paramsChallenges = "";
    let dataChallenge = "";

    switch (idChallenge) {
      case 0:
        // setCurrentChallenge(dataPost[position].id);
        return (
          <form onSubmit={methodsChallenge.isEmailLegal} id="myForm">
            <br />
            <p>{controlsPage.lblAccessToEmail?.value}</p>
            <div className="camp-radio">
              <div className="radius-opt">
                <input id="acept" type="radio" name="optboolean" value={true} />
                <label htmlFor="acept" >{controlsPage.rbtHasAccessToEmail?.value}</label>
              </div>
              <div className="radius-opt">
                <input id="negat" type="radio" name="optboolean" value={false} />
                <label htmlFor="negat">{controlsPage.rbtWithoutAccessToEmail?.value}</label>
              </div>
            </div>
            <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
            <button className="button--default button--second"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.value}
            </button>
            <button type="submit" className="button--default button--primary ">
            {controlsPage.smtSend?.value}
            </button>
            </div>
          </form>
        );
      case 1:
        setIsPass(true);
        // ingresa codigo que llega al correo electronico.


        paramsChallenges = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
          idRequest: idConnection,
          sendToMainEmail:enableEmail
        }).toString();
        // console.log(paramsChallenges)
        dataChallenge = strChallenge.createItemParams({
          param: paramsChallenges,
          tenant: clientName,
          headerEx:{idLanguageUser:props.idLng},
          ct: "json",
          reload:true,
          cancelIcon:true
        });

        setCurrentChallenge(dataPost[position].id);
        return (
          <form onSubmit={methodsChallenge.responseChallenge}>
            <br />
            <h3 className="clr--dark-II">{controlsPage.lblCodeVerification?.value}</h3>
            <p>{enableEmail?
            controlsPage.lblCodeMailInstitutionalDescription?.value
            :controlsPage.lblCodeVerificationMailDescription?.value}
            </p>
            <InputFile
              name="valueResponse"
              type="password"
              withOutSpace={true}
              onChange={() => {}}
              maxLength={controlsPage.txtEntertheverificationcode.maxlength}
              minLength={controlsPage.txtEntertheverificationcode.minlength}
              // msgError={controlsPage.txtEntertheverificationcode?.languagewebcontrols[0].infoMessage}
            />
            <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
            <button className="button--default button--second"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.value}
            </button>
            <button type="submit" className="button--default button--primary ">
            {controlsPage.smtSend?.value}
            </button>
            </div>
          </form>
        );
      case 2:
        //mostrar mensaje "señor usuario, se le enviará un correo electronico."
        setIsPass(true);
        paramsChallenges = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
          idRequest: idConect,
          sendToMainEmail:enableEmail
        }).toString();
        dataChallenge = strChallenge.createItemParams({
          param: paramsChallenges,
          tenant: clientName,
          headerEx:{idLanguageUser:props.idLng},
          reload: true,
          cancelIcon:true
        });
        dataChallenge
          .then((e) => {
            // console.log(e);
          })
          .catch((error) => {
            // console.log(error);
          });
        // console.log(dataChallenge)
        return (
          <>
          <br />
          <div className="w10 clr--dark-II" style={{textAlign:'left'}}>
            <h3>{controlsPage.lblLinkVerification?.value}</h3>
          </div>
            <p>{enableEmail?
            controlsPage.lblLinkMailInstitutionalDescription?.value
            :controlsPage.lblLinkMailDescription?.value}
            </p>
            <br></br>
            <div className="c--flex-wse">
            <button className="button--default button--second"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.value}
            </button>
            </div>
          </>
        );
      case 3:
        let quest = [];
        //mostrar preguntas asociadas que debe responder
        paramsChallenges = new URLSearchParams({
          username: state.user,
          domain: state.roll,
          verification: challengesID,
          challenge: dataPost[position].id,
          idRequest: idConnection,
          sendToMainEmail:enableEmail
        }).toString();
        dataChallenge = await strChallenge.createItemParams({
          param: paramsChallenges,
          headerEx:{idLanguageUser:props.idLng},
          tenant: clientName,
          reload:true,
          cancelIcon:true
        });
        dataChallenge.data.map((qs) => {
          quest.push(qs);
          setQuestions([questions, qs]);
        });
        return (
            // quest.map((_quest, index) => {
                <>
                <br />
                <h3 className="clr--dark-II">{controlsPage.lblQuestionVerification?.value}</h3>
                <p>{controlsPage.lblQuestionVerificationDescript?.value}</p>
                    <form onSubmit={methodsChallenge.prueba}>
                        {
                            quest.map((_quest, index) => {
                                return(
                                <>
                                <label>{_quest.question}</label>
                                <InputFile
                                name={_quest.id}
                                key={_quest.id}
                                type="password"
                                onChange={(value)=>handleChange(value,_quest.id)}
                                maxLength={controlsPage.txtPleaseEnterYourAnswer.maxlength}
                                minLength={controlsPage.txtPleaseEnterYourAnswer.minlength}
                                isQuestion="true"
                                />
                                </>
                                )
                            })
                        }
                        <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
                        <button className="button--default button--second"  type="button" onClick={rediref}>
                        {controlsPage.smtCancel?.value}
                        </button>
                          <button
                              type="submit"
                              className="button--default button--primary"
                          >
                          {controlsPage.smtSend?.value}
                          </button>
                        </div>
                        
                    </form>
                </>
                // <h1>fdkldfjl</h1>;
            // })
        );
        case 4:
          const textChallenge={
            1:controlsPage.lblCodeVerification?.value,
            2:controlsPage.lblLinkVerification?.value,
            3:controlsPage.lblQuestionVerification?.value
          }
          // const iconChallenge ={
          //   1:AutoFillTemplateIcon,
          //   2:AccountActivityIcon,
          //   3:AccountActivityIcon
          // }
          // setCurrentChallenge(dataPost[position].id);
          return (
            <form >
              <br />
              {/* <p>{controlsPage.lblAccessToEmail?.languagewebcontrols[0].value}</p> */}
                  <h3>{controlsPage.lblOptionalVerificationMethods?.value}</h3>
              <p>{controlsPage.lblSelectOneOfTheFollowingVerificationMethods?.value}</p>
              <div className="">
                { dataPost ? 
                dataPost.map((optChallenge) => {
                    return(
                    <>
                    <div key={optChallenge.idChallenge} className="" >
              <ButtonBlock onClick={()=>setDataPost([optChallenge])}
                text=
                {textChallenge[optChallenge.idChallenge]} />
              </div>
  
                                
                    </>
                    )
                }) : <p>No hay desafíos desponibles</p>
                }          
              </div>
              <div className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse">
              <button className="button--default button--second"  type="button" onClick={rediref}>
                {controlsPage.smtCancel?.value}
              </button>
              </div>
            </form>
          );
      // if(questions){
      // return(
      //     <>
      //         <form onSubmit={methodsChallenge.responseChallenge}>
      //             <label>{questions[0].question}</label>
      //             <button
      //                 type="submit"
      //                 className="button--default button--primary"
      //             >
      //                 ENVIAR
      //             </button>
      //         </form>
      //     </>
      // )
      // }else{
      //     <h1>sin preguntas</h1>
      // }

      // questions.map(qs =>{
      //     console.log(qs)
      // })

      // return (
      //     <p>Preguntas de seguridad</p>
      // );
      // case 4:
      //     alert("entra" + idChallenge)
      //     //acá debe ingresar un codigo que trae de la aplicación

      //     // setParamsChallenges(new URLSearchParams({
      //     //     username: state.user,
      //     //     domain: state.roll,
      //     //     verification: challengesID,
      //     //     challenge: "TLV07pFbbgk="
      //     // }).toString());
      //     // strChallenge.createItemParams({
      //     //     param: paramsChallenges,
      //     //     tenant: clientName
      //     // });
      // return (
      //     <p>Codigo de verificacion BSR Mobile</p>
      // );
      // case 5:
      //     //devuelve base 64

      //     // setParamsChallenges(new URLSearchParams({
      //     //     username: state.user,
      //     //     domain: state.roll,
      //     //     verification: challengesID,
      //     //     challenge: "TLV07pFbbgk="
      //     // }).toString());
      //     // strChallenge.createItemParams({
      //     //     param: paramsChallenges,
      //     //     tenant: clientName
      //     // });
      // return (
      //     <p>Codigo Qr BSR Mobile</p>
      // );
      default:
        return <p>Desafios no definidos, comuniquese con soporte.</p>;
    }
  };

  useEffect(() => {
    if (requiredChallenge.length > 0) {
      // alert("tiene desafios requeridos");
      // console.log(requiredChallenge);
    } else if (optionalChallenge) {
      // alert("tiene desafios opcionales");
    }
  }, [requiredChallenge, optionalChallenge]);

  useEffect(() => {
    
    if (state.user === undefined || state.roll === undefined) {
      navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
    }
  }, []);

  /************************
   * @resturns
   ************************/

  return (
    <>
      {requiredChallenge.length == 1 && (
        <>
          {/* {requiredChallenge[0].idChallenge} */}
          {requiredChallenge[0].challengeComponent}
          {/* <p>Gestor de cambio o recuperación de contraseña</p>
                    <ButtonFloat icon={<a href="/LoginForm">&#x1F511;</a>} type={1} typef="btnFloat-header" text="Opciones de inicio de sesión" />
                    <label>Seleccione al tipo de restauración para la contraseña.</label>
                    <ChoiceGroup defaultSelectedKey={0} options={itemsChoice} required={true} className="container--choicegroup"/>
                    <Checkbox label="Este proceso requiere la aceptación de términos y condiciones, conforme a la solicitud de autorización y política de tratamiento de datos personales."/> */}
          {/* <ContainerCaptcha/> */}
          {/* <section> */}
          {/* <button className={isPass?"button--default button--disabled":"button--default button--primary"} onClick={()=>{setPosition(position+1)}} disabled={isPass}>SIGUIENTE</button>  */}
          {/* <button onClick={()=>setPosition(position + 1)} className={isPass?"button--default button--disabled":"button--default button--primary"} disabled={isPass}>SIGUIENTE</button> 
                    </section>   */}
        </>
      )}
    </>
  );
};

export default ResetPassword;
