//****Librarys ****//
import React, { useContext, useEffect, useState } from 'react';
import {render} from "react-dom";
import { useNavigate, useParams } from "react-router-dom";
import { isStrongPassword, isByteLength, isInt } from 'validator'
import CookieJS from 'js-cookie';

//*****Componnents*****//
import InputFile from '../components/controls/inputFile/inputFile.component';
import { mergeStyles } from "@fluentui/react/lib/Styling";


//*****helpers*****//
import { consulta, RequestFetch } from '../utils/Requests/requests.Controller';


//*****Context*****//
import ContainerBaseLoading from '../components/container/containerLoading/containerBaseLoading.component';

//*** setting  ***/
import {PATH_SSO, SSO_URI} from '../assets/settings/settinsrouters';

//*****File*****//
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
import { FontIcon, Slider } from '@fluentui/react';
import LenguajeContext from '../context/lenguaje/lenguajeContext';


const RecoverPassword = ({states,idChallenger,mConfirmation, idLng, supportCode}) => {

  /************************
  * @arguments 
  ************************/
  const state = states
  const challengesID = idChallenger
  const requestsPolices = new RequestFetch(`Access/passwordPolicies/${state.user}/${state.roll}`);
  const requestResetPassword = new RequestFetch(`Access/resetPassword/${state.user}/${state.roll}`)
  const idConnection = CookieJS.get("idConnection");
  const { clientName } = useParams();  
  const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
  const navigate = useNavigate()
  const [arrayPolices, setArrayPolices ] = useState(null)
  const [valuesReg, setValuesReg] = useState([])
  const [styleButton, setstyleButton] = useState("button--default button--disable")
  const [score, setScore] = useState(0)
  const [type1, setType1] = useState({})
  const [type2, setType2] = useState({})
  const [type3, setType3] = useState({})
  const [type4, setType4] = useState({})
  const [type5, setType5] = useState({})
  const [words, setWords] = useState()
  const [ids, setIds] = useState([])
  const [enableButton, setEnableButton] = useState(false)
  const [passString, setPassString] = useState('')
  const arr = []

  const [valRes, setValRes] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [numberDefault, setNumberDefault] = useState(0)
  const [colorSlider, setColorSlider] = useState('gray')
  const [colorIcon, setColorIcon] = useState('gray')
  const [textOnPages, setTextOnPages] = useState({})
  const [disableText, setDisableText] = useState(true)

  const [isLoad, setIsLoad] = useState(false)
  const [conPassword,setConPassword]= useState({
    password:'',
    repassword:''
  })
  const {password,repassword}=conPassword;

  const actionConfirm=()=>{
    navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`)
 }
  
 const indexedDB = window.indexedDB
 let db

 const requestInfo = indexedDB.open('textList',1)
 useEffect(() => {
     requestInfo.onsuccess =async()=>{
         // alert('sdsdsd')
         db = requestInfo.result
         setTextOnPages(await consulta(db)) 
     }
 }, [])

 const [controlsPage, setControlsPage] = useState({});

 // ----------------------------------------------------------------------------------------------------------------------------

 /************************
  * @methods
  ************************/
  useEffect(() => {
   if(state.user === '' || state === ''){
       navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`);
   }
   // const response = Requests.getById({id:'', tenant: clientName });
   // response.then(data=>{
   let controlsPage = {};
   if (stateLanguage.lenguaje !=0) {
     stateLanguage.lenguaje.map((pass) => {
           if (pass.path == 'verification-challenge' || pass.path == 'enable-account' || pass.path == 'controls') {
             pass.webcontrolspages.map((data) => {
               controlsPage[data.idHtml] = data;
             })
             setControlsPage(controlsPage)
           }
                        
       })           
   }else{
     if (Object.entries(textOnPages).length > 0) {
         textOnPages.ssoPagesData.map((pass) => {
           if (pass.path == 'verification-challenge' || pass.path == 'enable-account' || pass.path == 'controls') {
             pass.webcontrolspages.map((data) => {
               controlsPage[data.idHtml] = data;
             })
             setControlsPage(controlsPage)
           }
         })
     }
   }

   // })
 }, [textOnPages, stateLanguage])


  const MethodsFetch = {
    pahtPassword: async (data) => {
      data.preventDefault();
      setEnableButton(!enableButton)
      let pass1 = data.target[0].value
      let pass2 = data.target[1].value
      if (score == 5) {
        if ((pass1 == pass2) && (pass1 != null) && (pass2 != null)) {
            const responseValue = {
              password: data.target[0].value,
            };
    
            const paramsResponse = new URLSearchParams(responseValue).toString();
            const headers = {idLanguageUser:idLng}
            if (idChallenger) {
              Object.assign(headers,{verification: challengesID})
            }else{
              Object.assign(headers,{supportCode:supportCode})
            }
            const dataResponse = await requestResetPassword.createItemParamsPass({
              ct: "encode",
              tenant: clientName,
              body: paramsResponse,
              headerEx:headers,
              // reload:true,
              cancelIcon:true
            });
            if(dataResponse.status == 200){
              let infor ={
                title: controlsPage.lblSuccessfulProcess?.languagewebcontrols[0].value,
                description: mConfirmation,
                type: 'success'
              }
              render(<ModalFluent
                props={infor}
                actionOnClick={actionConfirm}
                objectRequest={actionConfirm}
                redirect={true}
                cancelIcon={true}
                />,
                document.getElementById("modal"))
            }
        }
      }
    } 
  } 

  useEffect(() => {
    // let arr = []
    // arr.push(valRes)
    setValuesReg([...valuesReg,valRes])
  }, [valRes])
  
 
  
  useEffect(async () => {
    let values1 = []
    let values2 = []
    let values3 = []
    let values4 = []
    let values6 = []
    const polices = await requestsPolices.getItem({ 
      tenant: clientName, param:{idLanguage:idLng}, 
      headerEx:{
        idLanguageUser:idLng, 
        ...idChallenger?{verification: challengesID}:{supportCode: supportCode}
      }
    })
    setArrayPolices(polices.data.data);
    setWords (Object.keys(polices.data.data))
    polices.data.data.map(item => {
      item.values.map(values => {
        
        //if para restriccion de texto
        if (values.idSecurityPolicyType == 1) {
          values1.push(values.value)
          setType1({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values1
          })
        }

        //if para restriccion de texto
        if (values.idSecurityPolicyType == 3 && values.data) {
          values1.push(values.data)
          setType1({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values1
          })
        }
        
        //if para cacteres requeridos
        if (values.idSecurityPolicyType == 2) { 
          values2.push(values.value)
          setType2({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values2
          })
        }
        
        //if para loonguitud maxima
        if (values.idSecurityPolicyType == 4) {
          values4.push(values.value)
          setType4({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values.value
          })
        }
        
        //if para longuitud minima
        if (values.idSecurityPolicyType == 5) {
          
          setType5({
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values.value
          })
        }

        // if para los numeros validos
        if (values.idSecurityPolicyType == 6) {
          values3.push(values.value)
          setType3({...type3, 
            description: item.description,
            id: values.idSecurityPolicyType,
            values: values3
          })
        }
      })
    })      
  }, [idLng, ])

    /************************
   * @methods
   ************************/
    const contadorPunt = (id) =>{
      arr.push(id)
      setIds([...arr])
      
    }
    //#region Consultar el id del usuario
    const actionOnClick = () => {
      let infor =null
    }
    const confirmAction = () => {
      let infor= null
    }
    const useStyles = {
      activeSection: { background: colorSlider },
      thumb: { background: colorSlider, border: colorSlider },
      titleLabel: {
        color: colorSlider,
        paddingLeft: '7px',
        'font-size': '12px'
      }
    };
    useEffect(() => {
      let col = 2
      if(ids.length > 0 ){
        col = ids.length * ids.length
        setColorSlider(`rgb(255, ${col}${ids.length}, 0)`)
        setColorIcon('gray')
        if(ids.length == words.length){
          setScore(5)
          setErrorMessage(controlsPage.lblThePasswordCompliesWithAllSecurityPolicies?.languagewebcontrols[0].value)
          setColorSlider('#15A215')
          setColorIcon('#15A215')
          setEnableButton(true)
        }else{
          setEnableButton(false)
        }
      }
    }, [passString])
    
    /************************
     * @hooks
     ************************/         
    const confirpass = (pass)=>{      
      if(pass.indexOf(' ')>= 0){
        setPassString('')
        setErrorMessage(controlsPage.lblMustNotContainSpaces?.languagewebcontrols[0].value)
      }else{
        setPassString(pass)  
      

      if(Object.entries(type2).length != 0 ){
        if(blackListValidation(pass,type2.values)){
          contadorPunt(type2.id)
        }else{
          setErrorMessage(type2.description)
        }
      }
      if(Object.entries(type3).length != 0 ){
        if(regValidation(pass,type3.values)){
          contadorPunt(type3.id)
        }else{
          setErrorMessage(type3.description)
        }
      }
      if(Object.entries(type5).length != 0){
        minLimt(pass,type5.values,type5.id)
      }
      if(Object.entries(type4).length != 0){
        maxLimt(pass,type4.values,type4.id)
      }
      if(Object.entries(type1).length != 0){
        if (blackListValidation(pass, type1.values)) {
          setErrorMessage(type1.description)
          setNumberDefault(1)
          setColorSlider('gray')
          setColorIcon('gray')
        }else{
          contadorPunt(type1.id)
        }
      }
    }
    }
    const maxLimt = (value,limit,id)=>{
      if (value.length <= limit ) {
        contadorPunt(id)
      }else{
        setErrorMessage(type4.description)
      }
    }
    const minLimt = (value,limit,id)=>{
        if (value.length >= limit ) {
          contadorPunt(id)
        }else{
          setErrorMessage(type5.description)
        }
    }
    const blackListValidation = (value, blackList) => {
      let invalid = false;
      if (blackList.length > 0) {
        blackList.forEach((item) => {
          if (value.toLowerCase().includes(item.toLowerCase()))
            invalid = true
        })
    }
      return invalid;
    }
        
    const regValidation = (name, reg) => { 
      return reg.every( resg => { 
        const regex = new RegExp(resg); 
        return regex.test(name); 
      }) 
    }
    
    const estilo = mergeStyles({
      color: colorIcon
    })

    const rediref = ()=>{
      window.location.href = `${SSO_URI}${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${clientName}/home`
    }
    
    return (
    <> 
    <div className="w10 clr--dark-II" style={{textAlign:'left', marginTop:'15px'}}>
      <h4>{controlsPage.lblDescriptionOfPasswordChange?.languagewebcontrols[0].value}</h4>
    </div>
      <form onSubmit={MethodsFetch.pahtPassword} className="container--flex c--flex-hi c--flex-dc c--flex-f1 w10">
        {isLoad?
          <ContainerBaseLoading />
          :''
        }
        <InputFile 
          type='password'
          name="password1"
          placeholder={controlsPage.txtNewPassword?.languagewebcontrols[0].value}  
          label={controlsPage.lblEnterTheNewPassword?.languagewebcontrols[0].value}
          onChange={(e)=>{setConPassword({password:e,repassword:repassword});confirpass(e)}}
          politics={controlsPage.OnClickSeePolicies?.languagewebcontrols[0].label} listPolicies={arrayPolices}
          disable={disableText} setDisable={(e)=>{setDisableText(e)}}
          style="wb"
          classLabel="mg5-h"
        />
        <div className="slider-like">
          <Slider
            className="w95"
            value={passString.length>0?ids.length:0}
            label={errorMessage}
            min={0} max={words?.length}
            showValue={false}
            styles={useStyles}
            disabled
          />
          <FontIcon aria-label="Like" className={`size-ico2 mrg-lg-neg ${estilo}`} iconName="Like" />
        </div>
        
        
        <InputFile 
            type='password'
            name="password2"
            placeholder={controlsPage.txtEnterYourPasswordAgain?.languagewebcontrols[0].value}
            label={controlsPage.lblConfirmTheNewPassword?.languagewebcontrols[0].value}
            onChange={(e)=>{setConPassword({password:password,repassword:e})}}
            disable={disableText}
            setDisable={(e)=>{setDisableText(e)}}
            classLabel="mg5-h"
            />
        {(password!=repassword && <label className='politics'>{controlsPage.lblPasswordsDoNotMatchPleaseCheck?.languagewebcontrols[0].value}</label>)} 
        <section className="top-button container--flex c--flex-wc c--flex-hc c--flex-wse" >
            {/* <button className={
              `button--default 
                ${password!=repassword && 'button--disabled'} 
                ${password==repassword && 'button--primary'} 
                `}
                    type="submit" disabled={password!=repassword}
            > */}
            <button className={
              `button--default--none ${password==repassword && enableButton? 'button--primary':'button--disabled'} `}
                    type="submit" disabled={!password==repassword && !enableButton}
            >
              {controlsPage.smtSend?.languagewebcontrols[0].value}
            </button>
            <button className="button--default button--second"  type="button" onClick={rediref}>
              {controlsPage.smtCancel?.languagewebcontrols[0].value}
            </button>
            
        </section>
      </form>
    </>
  )
}

export default RecoverPassword;