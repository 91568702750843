import React from 'react'
import KeyBoard from 'react-simple-keyboard'


const VirtualKeyBoard = (props)=>{

    const KeysLayout = {
        default:[
            "{contrs} q w e r t y u i o p {bksp}",
            "a s d f g h j k l ñ {enter}",
            "{shift} z x c v b n m , .",
            "{alt} {space} {alt} {return}"
        ],
        shift:[
            "Q W E R T Y U I O P {bksp}",
            "A S D F G H J K L Ñ {enter}",
            "{shift} Z X C V B N M , .",
            "{alt} {space} {alt} {return}"
        ],
        alt:[
            "1 2 3 4 5 6 7 8 9 0 {bksp}",
            `@ # $ & * ( ) ' "`,
            "{shift} % - + = / ; : ! ? , .",
            "{default} {space} {default} {return}"
        ]
    }

    const KeysDisplay = {
        "{contrs}": "●",
        "{alt}": "123?",
        "{bksp}": "⌫",
        "{shift}": "⇧",
        "{default}": "ABC",
        "{enter}": "<|",
        "{space}": "  ______________  ",
        "{altright}": "123?",
        "{return}": "return"
    }


    return(
        <KeyBoard 
            layoutName = {props.layoutName} 
            layout = {KeysLayout}
            display = {KeysDisplay}
            onChage = {(input)=>props.onChage(input)}
            onKeyPress = {(button)=>props.onKeyPress(button)}
        />
    )
}

export default VirtualKeyBoard