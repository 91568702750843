//*****Setting*****//
import {SERVISE_URI} from '../../assets/settings/settinsrouters';
import CookieJS from 'js-cookie';
//*****Cookies *****//
//import JSCookies from 'js-cookie'
//const token = JSCookies.get('token')

export const getLanguages = async(dataToken, clientName) => {
    const dataClient = JSON.parse(CookieJS.get('dataClient'));
    const { idClient } = dataClient;
    const url = `${SERVISE_URI}api/Access/language/${idClient}`;
    const resp = await fetch( url, {
        headers:{
            'Accept': 'application/json',
            'content-Type': 'application/json'
        }
    } );
    const { data } = await resp.json();
    
    //console.log(data);
    const languages = data.map( lng => {
        return{
            id: lng.id,
            name: lng.name
        }
    })
    return languages;
}

