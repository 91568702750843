import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/auth/authContext';
import { RequestFetch, consulta } from '../utils/Requests/requests.Controller';
import { useNavigate } from 'react-router-dom';
import LenguajeContext from '../context/lenguaje/lenguajeContext';
import ModalFluent from '../components/tools/modalFluent/ModalFluent';
import { render } from 'react-dom';
import CookiesJS from 'js-cookie'
import RecoverPassword from './RecoverPassword';
import { PATH_SSO } from '../assets/settings/settinsrouters';

const SupportPassword = (props) => {

    const usrServ = new RequestFetch('Access/user')
    const {getUser, error, getError, state} = useContext(AuthContext);
    const {getLenguaje, stateLanguage} = useContext(LenguajeContext);
    const [serviceOpt, setServiceOpt] = useState({})
    const [controlsPage, setControlsPage] = useState({})
    const dataQ = window.location.href;
    const dataU = window.location.search;
    const nameClient = CookiesJS.get('nameClient')
    const navigate = useNavigate();
    
    const url = window.location.href;
    const riParam = url.split("ri=")[1]?.split("&")[0];
    const [textOnPages, setTextOnPages] = useState({});
    const [renderComp, setRenderComp] = useState(null)

  const indexedDB = window.indexedDB;
  let db;

  const requestInfo = indexedDB.open("textList", 1);
  useEffect(() => {
    requestInfo.onsuccess = async () => {
      // alert('sdsdsd')
      db = requestInfo.result;
      setTextOnPages(await consulta(db));
    };
  }, []);

    const redirectHome = () =>{
        navigate(`/${PATH_SSO ? `${PATH_SSO}/` : ''}${nameClient}/home`);
    }
    
    useEffect(async() => {
      // props.idLng(props.controlsPage.idLng)
      // props.validLng(true)
      
      // if (!serviceOpt) {
        const promise = await usrServ.getItem({
            param: { code: riParam },
            tenant: nameClient
        });

        if (promise.data.data) {
            const {idDomain,userName,idLanguage} = promise.data.data;
            let contex = {roll:idDomain, user:userName, idLanguage:idLanguage}
            getUser(contex);
            let controlsPage = {}
            props.controlsPage.map((users) => {
              if (users.path == "controls" || users.path == "verification-challenge") {
                  users.webcontrolspages.map((data) => {
                      controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  })
              }
            })
            // navigate(`${PATH_SSO==''?PATH_SSO:'/'+PATH_SSO}/${nameClient}/ResetPassword`)
              props.idLng(idLanguage)
              setServiceOpt({idLng:idLanguage, states:contex, supportCode:riParam})
              getLenguaje(props.controlsPage)
            
        }else{
            promise.data.errors.map((error)=>{
            let info ={
                title:error.title,
                description:error.code+": "+error.detail,
                type:'danger',
            }
            render(
                <ModalFluent
                    props={info}
                    actionOnClick={(e)=>{redirectHome(e)}}
                    objectRequest ={(e)=>{redirectHome(e)}}
                    redirect={true}
                    cancelIcon={true}
                />,
                document.getElementById("modal")
            );
        })
        }
      // }
    }, [])
    
    useEffect(() => {
        let controlsPage = {};
        if (stateLanguage.lenguaje != 0) {
          stateLanguage.lenguaje.map((users) => {
                if (users.path == "controls" || users.path == "verification-challenge") {
                    users.webcontrolspages.map((data) => {
                        controlsPage[data.idHtml] = data.languagewebcontrols[0];
                    })
                }
              })
              setControlsPage(controlsPage)
              if (state != null && state != '') {
                setRenderComp(
                  <RecoverPassword idLng={state.idLanguage} states={state} mConfirmation={controlsPage.lblYourRequestHasBeenSuccessful?.value} supportCode={riParam}/>
                )
              }
        }else {
            if (Object.entries(textOnPages).length > 0) {
              textOnPages.ssoPagesData.map((users) => {
                if (users.path == "controls" || users.path == "verification-challenge") {
                  users.webcontrolspages.map((data) => {
                    controlsPage[data.idHtml] = data.languagewebcontrols[0];
                  });
                }
              });
              setControlsPage(controlsPage);
            }
          }
    }, [stateLanguage])
    
  return (
    <>
    {renderComp && (renderComp)}
    </>
  )
}

export default SupportPassword;