//****Librarys ****//
import React, { Fragment, useState } from 'react';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
// import {TextField} from 'office-ui-fabric-react';

//*****Componnents*****//
import ImgQuat from '../components/tools/imgQuad/imgQuad.component';
import ButtonFloat from '../components/tools/buttonFloat/buttonFloat.component';
// import { useHistory } from 'react-router';
import { useNavigate } from 'react-router-dom';

/*****************
 *****************
 * @returns copomnent BDM-SSO
 * 
 *****************
 *****************/

const LoginQR = () =>{       

    /************************
     * @arguments 
     ************************/
        const navigate = useNavigate();

    /************************
     * @methods 
     ************************/

    /************************
     * @hooks 
     ************************/
                
    /************************
     * @resturns 
     ************************/
        return(
            <Fragment>
                <p>Gestor de cambio o recuperación de contraseña</p>
                <label>Opciones de inicio de sesión</label>
                <span>Iniciar sesión con autenticar QR desde ores impuso, consetetur sadipscing elitr.</span>
                <ImgQuat />
                <section>
                    <button className="button--default button--primary" onClick={()=>navigate('/')}>SIGUIENTE</button> 
                </section>
            </Fragment>
        );
}

export default LoginQR;